.p-top {
  &__mv {
    height: 680px;
    overflow: hidden;
    position: relative;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
    &--news {
      @extend .p-top__ttl;
      margin-bottom: 15px;
      @media (--mobile) {
        margin-bottom: 0;
        padding: 15px 0 0;
      }
    }
  }
  &__morebtn {
    @include z-contents(2);
    @media (--large) {
      letter-spacing: 0.1em;
      position: absolute;
      top: 10px;
      right: 0;
    }
    @media (--mobile) {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      width: 400px;
      @media (--mobile) {
        font-size: 1.3rem;
        width: 95%;
      }
      a {
        border: #cfcfcf 1px solid;
        display: block;
        padding: 8px 10px;
      }
      &--short {
        width: 240px;
      }
    }
  }
}
.lovot {
  border: #000 10px solid;
  margin: 120px auto 0;
  opacity: 0;
  padding: 20px;
  transition: opacity 1s ease 1s;
  width: 960px;
  &.inview {
    opacity: 1;
  }
  @media (--mobile) {
    margin: 60px auto 0;
    padding: 15px;
    width: 94%;
  }
  &__teaser {
    opacity: 0;
    margin-top: 120px;
    text-align: center;
    transition: opacity 1s ease 1s;
    @media (--mobile) {
      margin: 60px auto 0;
      width: 85%;
    }
    &.inview {
      opacity: 1;
    }
    a {
      display: inline-block;
      @media (--large) {
        transition: opacity .3s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  &__wrap {
    background-color: #fff;
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    padding: 40px 45px;
    @media (--mobile) {
      padding: 30px 10px 45px;
    }
    // &::before {
    //   content: "X";
    //   color: $color-lightGray;
    //   font-size: 45rem;
    //   font-family: $fontGotham;
    //   font-weight: bold;
    //   line-height: 1;
    //   margin: auto;
    //   position: absolute;
    //   top: -73px;
    //   right: -62px;
    //   @media (--mobile) {
    //     top: -20%;
    //     right: -30%;
    //   }
    // }
  }
  &__frame {
    height: 25px;
    position: absolute;
    width: 34px;
    @media (--mobile) {
      height: 22px;
      width: 22px;
    }
    &::before {
      background-color: #000;
      height: 25px;
      content: "";
      position: absolute;
      width: 2px;
      @media (--mobile) {
        height: 18px;
      }
    }
    &::after {
      background-color: #000;
      height: 2px;
      content: "";
      position: absolute;
      width: 25px;
      @media (--mobile) {
        width: 18px;
      }
    }
    &--top-left {
      top: 0;
      left: 0;
      &::before {
        top: 0;
        left: 0;
      }
      &::after {
        top: 0;
        left: 10px;
      }
    }
    &--top-right {
      top: 0;
      right: 0;
      &::before {
        top: 0;
        right: 0;
      }
      &::after {
        top: 0;
        right: 10px;
      }
    }
    &--bottom-left {
      left: 0;
      bottom: 0;
      &::before {
        left: 0;
        bottom: 0;
      }
      &::after {
        left: 10px;
        bottom: 0;
      }
    }
    &--bottom-right {
      right: 0;
      bottom: 0;
      &::before {
        right: 0;
        bottom: 0;
      }
      &::after {
        right: 10px;
        bottom: 0;
      }
    }
  }
  &__inner {
    position: relative;
    p {
      @media (--mobile) {
        width: (258px / 2);
        margin: 10px auto 0;
      }
    }
  }
  &__ttl {
    font-size: 3rem;
    font-weight: normal;
    text-align: center;
    word-wrap : break-word;
    color: $color-lovotPink;
    letter-spacing: 0.3em;
    @media (--mobile) {
      font-size: 2.8rem;
    }
    strong {
      color: $color-lovotRed;
      font-weight: normal;
    }
    &--en {
      @extend .lovot__ttl;
      letter-spacing: 0.1em;
      @media (--large) {
        font-size: 2.6rem;
      }
      @media (--mobile) {
        letter-spacing: 0.1em;
        font-size: 2rem;
      }
      span {
        @media (--large) {
          font-size: 2.5rem;
          letter-spacing: 0.3em;
        }
        @media (--mobile) {
          font-size: 1.7rem;
        }
      }
      strong {
        @media (--large) {
          font-size: 2.6rem;
          letter-spacing: 0.4em;
        }
        @media (--mobile) {
          font-size: 1.7rem;
        }
      }
    }
    a {
      color: $color-lovotPink;
      position: relative;
      @media (--large) {
        &::before {
          background-color: $color-lovotPink;
          height: 1px;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          transition: width .3s;
          width: 0;
        }
        &:hover {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
  }
  &__read {
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2;
    text-align: center;
    word-wrap : break-word;
    @media (--large) {
      margin-top: 10px;
    }
    @media (--mobile) {
      margin-top: 10px;
      font-size: 1.1rem;
      line-height: 2.1;
    }
    // @media (--mini2) {
    //   font-size: 1.5rem;
    //   letter-spacing: 0.2em;
    // }
    strong {
      color: $color-lovotRed;
      font-weight: normal;
    }
    &--en {
      @extend .lovot__read;
      letter-spacing: 0.1em;
      @media (--large) {
        margin-top: 20px;
        font-size: 1.2rem;
      }
      @media (--mobile) {
        letter-spacing: 0.1em;
        font-size: 1rem;
      }
      span {
        @media (--large) {
          font-size: 2.5rem;
          letter-spacing: 0.3em;
        }
        @media (--mobile) {
          font-size: 1.7rem;
        }
      }
      strong {
        @media (--large) {
          font-size: 2.6rem;
          letter-spacing: 0.4em;
        }
        @media (--mobile) {
          font-size: 1.7rem;
        }
      }
    }
  }
  &__text {
    @media (--mobile) {
      font-size: 1.1rem;
      letter-spacing: 0.1em;
      margin-top: 10px;
      text-align: center;
    }
  }
  &__release {
    color: $color-lovotRed;
    font-size: 1.6rem;
    letter-spacing: 0.2em;
    margin: 30px auto 40px;
    text-align: center;
    @media (--mobile) {
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      margin: 10px auto 20px;
    }
    @media (--mini2) {
      font-size: 1.1rem;
    }
    &--en {
      @extend .lovot__release;
      letter-spacing: 0.1em;
      line-height: 1.5;
    }
    span {
      font-size: 1.8rem;
      @media (--mobile) {
        font-size: 1.4rem;
      }
      @media (--mini2) {
        font-size: 1.3rem;
      }
    }
    small {
      font-size: 1.4rem;
      @media (--mobile) {
        font-size: 1rem;
      }
    }
  }
  &__cont {
    @media (--large) {
      display: table;
      width: 100%;
    }
    @media (--mobile) {
      margin-top: 15px;
    }
  }
  &__photo {
    @media (--large) {
      display: table-cell;
      vertical-align: top;
      padding-right: 20px;
    }
    @media (--mobile) {
      text-align: center;
    }
  }
  &__caption {
    @media (--large) {
      display: table-cell;
      vertical-align: top;
    }
    @media (--mobile) {
      margin: 15px auto 0;
      width: 95%;
    }
    p {
      // font-size: 1.4rem;
      // hyphens: auto;
      // line-height: 2;
      // letter-spacing: 0.1em;
      // text-align: center;
      width: 143px;
      margin: 25px auto 0;
      &.en {
        margin: 35px auto 0;
      }
    }
    .c-btn {
      @media (--large) {
        position: absolute;
        right: 10px;
        bottom: 0;
      }
      @media (--mobile) {
        margin: 10px auto 0;
      }
    }
  }
}
.sitecontent {
  margin: 120px auto 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  width: 960px;
  transition: opacity 1s ease-in;
  @media (--mobile) {
    margin: 60px 0 40px;
    width: 100%;
  }
  + .sitecontent {
    margin-top: 80px;
    @media (--mobile) {
      margin-top: 0;
    }
  }
  &--last {
    @extend .sitecontent;
    margin-bottom: 120px;
    @media (--mobile) {
      margin-bottom: 0;
      padding-bottom: 40px;
    }
  }
  &.inview {
    opacity: 1;
  }
  &__photo {
    height: 100%;
    position: absolute;
    transition: clip .7s cubic-bezier(0.5, 0, 0.865, 0.915), background-position 1s ease-out;
    width: 100%;
    @media (--mobile) {
      transition: clip .7s cubic-bezier(0.5, 0, 0.865, 0.915);
    }
  }
  &.inview &__mv {
    opacity: 1;
  }
  &__mv {
    height: 400px;
    opacity: 0;
    position: relative;
    transition: opacity 1s, background-position 1s ease-out;
    @media (--mobile) {
      transition: opacity 1s;
    }
    @media (--mobile) {
      height: 350px;
      margin-left: auto;
      width: 94%;
    }
    &--philosophy {
      background: url(../images/top/philosophy.jpg) no-repeat center 50px;
      background-size: cover;
      @media (--mobile) {
      background-position: center 15px;
      }
      @media (--mini) {
        background: url(../images/top/philosophy_sp.jpg) no-repeat center 25px;
        background-size: cover;
      }
      .sitecontent__photo {
        background: url(../images/top/philosophy_gr.jpg) no-repeat center 50px;
        background-size: cover;
        @media (--mobile) {
          background-position: center 15px;
        }
        @media (--mini) {
          background: url(../images/top/philosophy_gr_sp.jpg) no-repeat center 25px;
          background-size: cover;
        }
      }
    }
    &--recruit {
      background: url(../images/top/recruit.jpg) no-repeat center 50px;
      background-size: cover;
      @media (--mobile) {
        background-position: center 15px;
      }
      @media (--mini) {
        background: url(../images/top/recruit_sp.jpg) no-repeat center 25px;
        background-size: cover;
      }
      .sitecontent__photo {
        background: url(../images/top/recruit_gr.jpg) no-repeat center 50px;
        background-size: cover;
        @media (--mobile) {
          background-position: center 15px;
        }
        @media (--mini) {
          background: url(../images/top/recruit_gr_sp.jpg) no-repeat center 25px;
          background-size: cover;
        }
      }
    }
    &--culture {
      background: url(../images/top/culture.jpg) no-repeat center 50px;
      background-size: cover;
      @media (--mobile) {
        background-position: center 15px;
      }
      @media (--mini) {
        background: url(../images/top/culture_sp.jpg) no-repeat center 25px;
        background-size: cover;
      }
      .sitecontent__photo {
        background: url(../images/top/culture_gr.jpg) no-repeat center 50px;
        background-size: cover;
        @media (--mobile) {
          background-position: center 15px;
        }
        @media (--mini) {
          background: url(../images/top/culture_gr_sp.jpg) no-repeat center 25px;
          background-size: cover;
        }
      }
    }
    &--ourteam {
      background: url(../images/top/ourteam.jpg) no-repeat center 50px;
      background-size: cover;
      @media (--mobile) {
        background-position: center 15px;
      }
      @media (--mini) {
        background: url(../images/top/ourteam_sp.jpg) no-repeat center 25px;
        background-size: cover;
      }
      .sitecontent__photo {
        background: url(../images/top/ourteam_gr.jpg) no-repeat center 50px;
        background-size: cover;
        @media (--mobile) {
          background-position: center 15px;
          background-size:  auto 120%;
        }
        @media (--mini) {
          background: url(../images/top/ourteam_gr_sp.jpg) no-repeat center 25px;
          background-size: cover;
        }
      }
    }
  }
  &__mask {
    background-color: rgba(250, 250, 250, 0.5);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    &--01 {
      transition: left 1.4s ease, width .7s ease-in;
    }
    &--02 {
      transition: left 1.5s ease .2s, width .7s ease-in;
    }
    &--03 {
      transition: left 1.4s ease .25s, width .7s ease-in;
    }
    &--04 {
      transition: left 1.4s ease .35s, width .7s ease-in;
    }
    &.active {
      left: 100%;
      width: 80px;
    }
  }
  &.inview &__category {
    opacity: 1;
  }
  &__category {
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: 0.13em;
    opacity: 0;
    position: absolute;
    top: 18px;
    right: 18px;
    transition: opacity 1s ease-in 1s;
    @media (--mobile) {
      font-size: 1.3rem;
      top: auto;
      right: 10px;
      bottom: -25px;
    }
  }
  &.inview &__ttl {
    opacity: 1;
  }
  &__ttl {
    align-items: center;
    background-color: rgba(51, 51, 51, 0.8);
    display: flex;
    color: #fff;
    font-size: 1.6rem;
    font-weight: normal;
    height: 200px;
    letter-spacing: 0.35em;
    line-height: 2;
    justify-content: center;
    opacity: 0;
    padding: 10px;
    position: absolute;
    top: 30px;
    left: 30px;
    transition: opacity 1s ease-in 1s;
    width: 200px;
    @media (--mobile) {
      font-size: 1.2rem;
      height: 156px;
      top: auto;
      left: 15px;
      bottom: -20px;
      width: 156px;
    }
    &--philosophy {
      @media (--large) {
        background-color: rgba(255, 255, 255, 0.4);
        color: $color-baseText;
      }
    }
    &--long-en {
      width:210px;
      span {
        letter-spacing: 0.2em;
        small {
          display: block;
          line-height: 1.5;
          letter-spacing: 0;
        }
      }
    }
  }
  &.inview &__caption {
    opacity: 1;
  }
  &__caption {
    font-size: 1.6rem;
    hyphens: auto;
    line-height: 2;
    letter-spacing: 0.12em;
    margin-top: 35px;
    opacity: 0;
    transition: opacity 1s ease-in 1s;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 35px auto 0;
      width: 80%;
    }
  }
  &.inview .c-btn {
    opacity: 1;
  }
  .c-btn {
    opacity: 0;
    margin: 30px auto 0;
    transition: opacity 1s ease-in 1s;
    @media (--mobile) {
      margin: 10px auto 0;
      width: 80%;
    }
  }
}
.pickup {
  margin: 0 auto 0;
  width: 960px;
  @media (--mobile) {
    background-color: #000;
    margin: 0 auto;
    padding: 40px 0 20px;
    width: 100%;
  }
  @at-root .p-misstype & {
    margin: 120px auto 0;
    @media (--mobile) {
      margin: 60px auto 0;
    }
  }
  @at-root .p-top & {
    position: relative;
    &::before {
      background-color: $color-bgGray;
      transition: width 1s ease .5s;
      @include inview-cover;
      @include z-contents(1);
    }
    &.inview {
      &::before {
        width: 0;
      }
      .pickup__article {
        &::before {
          width: 0;
        }
      }
    }
    &__article {
      position: relative;
      &::before {
        background-color: #000;
        transition: width 1s ease 1.5s;
        @include inview-cover;
        @include z-contents(0);
      }
    }
  }
  .p-top__ttl {
    @media (--mobile) {
      color: #fff;
    }
  }
  &__inner {
    font-size: 0;
    margin-top: 15px;
    @media (--large) {
      background-color: #000;
      padding: 40px 60px;
    }
    @media (--mobile) {
      margin: 15px auto 0;
      max-width: 400px;
      width: 80%;
    }
  }
  &__article {
    @media (--large) {
      display: inline-block;
    }
    &:nth-of-type(even){
      @media (--large) {
        margin-left: 40px;
      }
    }
    + li {
      @media (--mobile) {
        margin-top: 15px;
      }
    }
    a {
      display: block;
      @media (--large) {
        &:hover {
          .pickup__photo {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    figure {
      figcaption {
        color: #fff;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        margin-top: 20px;
        @media (--mobile) {
          font-size: 1.4rem;
          margin-top: 15px;
        }
      }
    }
  }
  &__photo {
    height: 200px;
    width: 400px;
    overflow: hidden;
    @media (--mobile) {
      height: auto;
      width: 100%;
    }
    img {
      transition: transform .5s;
    }
  }
}
.talksession {
  &--top {
    margin: 114px auto 0;
    position: relative;
    width: 960px;
    @media (--mobile) {
      background-color: #fff;
      margin: 0;
      padding: 60px 0 55px;
      //padding-bottom: 50px;
      width: 100%;
    }
  }
  &--top &__inner {
    &.inview {
      .p-top__morebtn {
        a {
          opacity: 1;
          bottom: 0;
        }
      }
    }
  }
  .p-top__morebtn {
    @media (--large) {
      a {
        position: relative;
        transition: opacity .5s ease 1s, bottom .5s ease 1s;
        opacity: 0;
        bottom: -20px;
      }
    }
    @media (--mobile) {
      position: absolute;
      right: 20px;
      bottom: 40px;
      width: 25%;
      a {
        background-color: $color-lightBlue;
        border: 0;
        color: #fff;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
.news {
  .p-top__ttl {
    @media (--mobile) {
      background-color: $color-baseBlue;
      color: #fff;
    }
  }
  .p-top__morebtn {
    @media (--mobile) {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      width: 80%;
    }
    a {
      @media (--mobile) {
        background-color: #fff;
        display: block;
        border: 0;
        color: $color-baseBlue;
        transition: color .3s;
        &::before {
          background-color: $color-baseBlue;
        }
      }
    }
  }
}
