
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/

$color-baseText: #333;
$color-lightText: #ccc;
$color-lightGray: #f8f8f8;
$color-bgGray: #f2f2f2;
$color-lovotRed: #af101c;
$color-baseBlue: #1c2636;
$color-lightBlue: #5ca1b5;
$color-bgtalk: #83b7c6;
$color-bordertalk: #257288;
$color-bglovot: #fff6e5;
$color-borderlovot: #e0bbbf;
$color-bgteam: #b9b292;
$color-borderteam: #a49a6c;
$color-lovotPink: #fe8e74;

@mixin z-loading($num){
  z-index: $num + 5000;
}

@mixin z-modal($num){
  z-index: $num + 4000;
}

@mixin z-header($num){
  z-index: $num + 3000;
}

@mixin z-footer($num){
  z-index: $num + 2000;
}

@mixin z-contents($num){
  z-index: $num + 1000;
}

@mixin inview-cover() {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

@mixin header-arrow() {
  &::before {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 0;
    margin: auto;
    position: absolute;
    top: 19px;
    left: 0;
    right: 0;
    transition: height .5s ease 1s;
    width: 25px;
    @include z-header(1);
  }
  &::after {
    border-style: solid;
    border-width: 0 0px 0px 0px;
    border-color: transparent transparent #333 transparent;
    content: "";
    height: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transition: border-width .5s ease 1s;
    @include z-header(1);
  }
}
@mixin header-arrow-inview() {
  &::before {
    height: 25px;
  }
  &::after {
    border-width: 0 3.5px 4px 3.5px;
  }
}


@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin hide-text() {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * element size
 */
@mixin size($width: auto, $height: auto) {
  width: $width;
  height: $height;
}

/**
 * position layout
 */
@mixin absolute-top-left($top: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin absolute-top-right($top: 0, $right: 0) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin absolute-bottom-left($bottom: 0, $left: 0) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

@mixin absolute-bottom-right($bottom: 0, $right: 0) {
  position: absolute;
  bottom: $bottom;
  right: $right;
}

@mixin absolute-all($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/**
 * images
 */
@mixin cover($img, $position: 0% 0%) {
  background: $img no-repeat $position;
  background-size: cover;
}
