.p-lovot {
  &__mv {
    height: 500px;
    overflow: hidden;
    @media (--mobile) {
      height: 230px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
  }
}
.aboutlovot {
  margin: 76px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 50px auto 0;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .p-lovot__ttl {
    font-size: 2.5rem;
    font-weight: normal;
    line-height: 2;
    letter-spacing: 0.4em;
    position: relative;
    text-align: center;
    @media (--mobile) {
      font-size: 1.7rem;
      line-height: 2.1;
      letter-spacing: 0.3em;
    }
    strong {
      color: $color-lovotRed;
      font-weight: normal;
    }
    &--en {
      @extend .p-lovot__ttl;
      @media (--large) {
        letter-spacing: 0.1em;
        font-size: 2.4rem;
      }
      @media (--mobile) {
        letter-spacing: 0.1em;
      }
      span {
        @media (--large) {
          font-size: 2.5rem;
          letter-spacing: 0.4em;
        }
        @media (--mobile) {
          letter-spacing: 0.3em;
        }
      }
    }
  }
  &__release {
    color: $color-lovotRed;
    font-size: 1.6rem;
    letter-spacing: 0.2em;
    margin: 25px auto 45px;
    text-align: center;
    @media (--mobile) {
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      margin: 10px auto;
    }
    &--en {
      @extend .aboutlovot__release;
      letter-spacing: 0.1em;
      line-height: 1.5;
    }
    span {
      font-size: 1.8rem;
      @media (--mobile) {
        font-size: 1.4rem;
      }
    }
    small {
      font-size: 1.4rem;
      @media (--mobile) {
        font-size: 1rem;
      }
    }
  }
  &__inner {
    margin-top: 30px;
    position: relative;
    @media (--mobile) {
      margin-top: 25px;
    }
  }
  &__photo {
    cursor: pointer;
    @media (--large) {
      transition: opacity .3s;
      &:hover {
        opacity: .5;
      }
    }
    @media (--large) {
      position: absolute;
      top: 10px;
      right: 0;
    }
    @media (--mobile) {
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: 300px;
    }
  }
  &__caption {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.11em;
    margin-top: -10px;
    padding-right: 34px;
    @media (--mobile) {
      font-size: 1.4rem;
      letter-spacing: 0em;
      margin: 30px auto 0;
      padding-right: 0;
      width: 84%;
    }
    p {
      + p {
        margin-top: 30px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
      em {
        color: $color-lovotRed;
        font-style: normal;
      }
    }
  }
}
.lovotkeyword {
  background-color: #fff;
  margin: 80px auto 0;
  padding: 80px 0 40px;
  width: 960px;
  @media (--large) {
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.1);
  }
  @media (--mobile) {
    margin: 60px auto 0;
    padding: 17px 0 40px;
    width: 100%;
  }
  &__ttl {
    color: $color-lovotRed;
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    position: relative;
    text-align: center;
    @media (--mobile) {
      font-size: 1.5rem;
      letter-spacing: 0.15em;
    }
    &::before {
      background-color: $color-borderlovot;
      height: 1px;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 245px;
      @media (--mobile) {
        width: 23%;
      }
    }
    &::after {
      background-color: $color-borderlovot;
      height: 1px;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 245px;
      @media (--mobile) {
        width: 23%;
      }
    }
  }
  &__inner {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.1em;
    margin: 40px auto 35px;
    padding: 25px 0 0;
    width: 880px;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 16px auto 15px;
      padding: 10px 20px 0;
      width: 94%;
    }
    p {
      + p {
        margin-top: 30px;
        @media (--mobile) {
          margin-top: 15px;
        }
      }
    }
  }
}
.talklovot {
  line-height: 2;
  @media (--large) {
    margin: 30px auto 0;
    width: 880px;
  }
  @media (--mobile) {
    margin: 0 auto;
    width: 90%;
  }
  &__navi {
    background-color: $color-bgtalk;
    & + & {
      margin-top: 20px;
    }
  }
  figure {
    @media (--large) {
      display: table;
      height: 100%;
    }
    figcaption {
      padding: 20px 40px;
      @media (--large) {
        display: table-cell;
        vertical-align: middle;
      }
      @media (--mobile) {
        padding: 20px;
      }
    }
  }
  &__photo {
    overflow: hidden;
    width: 280px;
    @media (--large) {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
    }
    @media (--mobile) {
      width: 100%;
    }
    picture {
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
      @media (--large) {
        min-height: 185px;
      }
      @media (--mobile) {
        height: 230px;
      }
    }
    img {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @media (--mobile) {
        height: auto;
        max-height: none;
        max-width: none;
        width: 100%;
      }
    }
  }
  &__vol {
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 0.15em;
    @media (--large) {
      margin-bottom: 5px;
    }
    @media (--mobile) {
      font-size: 1.7rem;
      line-height: 1.6;
      text-align: center;
      padding: 15px 0;
    }
    small {
      font-size: 1.6rem;
      margin-left: 0.5em;
      text-transform: capitalize;
      @media (--mobile) {
        font-size: 1.7rem;
      }
    }
    span {
      @media (--large) {
        margin-left: 1em;
      }
      @media (--mobile) {
        display: block;
      }
    }
  }
  &__chapter {
    li {
      letter-spacing: 0.1em;
      @media (--mobile) {
        line-height: 1.4;
        font-size: 1.4rem;
      }
      + li {
        @media (--mobile) {
          margin-top: 15px;
        }
      }
      a {
        color: #9cc8d3;
      }
      a[href] {
        background: linear-gradient(transparent 95%, #fff 0%);
        background-position: 0% bottom;
        background-repeat: no-repeat;
        background-size: 0 100%;
        color: #fff;
        transition: background-size .3s;
        @media (--large) {
          &:hover {
            background-size: 100% 100%;
          }
        }
      }
      span {
        @media (--mobile) {
          display: block;
        }
        + span {
          @media (--large) {
            margin-left: 1em;
          }
          @media (--mobile) {
            margin-top: 4px;
          }
        }
      }
    }
  }
}
