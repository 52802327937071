
.c-bread {
  border-bottom: $color-bgGray 1px solid;
  height: 30px;
  padding: 6px 20px;
  @media (--mobile) {
    display: none;
  }
  &__page {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    + li {
      &::before {
        content: " / ";
        font-weight: normal;
      }
    }
    a {
      font-weight: normal;
      transition: opacity .3s;
      &:hover {
        @media (--large) {
          opacity: .5;
        }
      }
    }
  }
  @at-root .p-news & {
    background-color: $color-bgGray;
    border: 0;
  }
  @at-root .p-talk & {
    background-color: $color-bgGray;
    border: 0;
  }
  @at-root .p-document & {
    border: 0;
  }
  @at-root .p-access & {
    border: 0;
  }
  @at-root .p-contact & {
    border: 0;
  }
  @at-root .p-lovot & {
    border-bottom: $color-borderlovot 1px solid;
  }
  @at-root .p-team & {
    background-color: $color-bgteam;
    border-bottom: $color-borderteam 1px solid;
    position: absolute;
    top: -30px;
    left: calc((100% - 960px) / 4);
    transition: clip .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    &__page {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
  @at-root .p-member & {
    background-color: $color-bgteam;
    border-bottom: $color-borderteam 1px solid;
    position: absolute;
    top: -30px;
    left: calc((100% - 960px) / 4);
    transition: clip .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    &__page {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
  @at-root .p-interview & {
    background-color: $color-bgtalk;
    border-bottom: $color-lightBlue 1px solid;
    position: absolute;
    top: -30px;
    left: calc((100% - 960px) / 4);
    transition: clip .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    &__page {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
  @at-root .p-about & {
    background-color: $color-baseText;
    border-bottom: #000 1px solid;
    position: absolute;
    top: -30px;
    left: calc((100% - 960px) / 4);
    transition: clip .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    &__page {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
  @at-root .p-misstype & {
    border: 0;
  }
  @at-root .p-404 & {
    border: 0;
  }
  @at-root .p-coming & {
    border: 0;
  }
  @at-root .p-privacy & {
    background-color: $color-bgGray;
    border: 0;
  }
}
