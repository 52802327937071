.recruit {
  margin: 70px auto 0;
  position: relative;
  width: 960px;
  @media (--mobile) {
    background-color: #fff;
    margin: 0 auto 0;
    padding: 42px 0 60px;
    width: 85%;
  }
  &__info {
    line-height: 2;
    letter-spacing: 0.1em;
    margin-bottom: 45px;
    @media (--large) {
      font-size: 1.6rem;
    }
    @media (--mobile) {
      margin-bottom: 25px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.1em;
    text-align: center;
    @media (--mobile) {
      font-size: 1.7rem;
      line-height: 1.7;
    }
  }
  &__photo {
    display: block;
    margin-top: 35px;
    text-align: center;
    @media (--mobile) {
      margin-top: 13px;
    }
  }
  &__text {
    line-height: 2;
    letter-spacing: 0.1em;
    margin-top: 30px;
    margin-bottom: 70px;
    @media (--large) {
      font-size: 1.6rem;
    }
    @media (--tablet) {
      margin-top: 11px;
      margin-bottom: 25px;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
  }
  &__inner {
    margin-top: 15px;
    @media (--large) {
      background-color: #fff;
      box-shadow: 0 2px 21px rgba(75, 33, 37, 0.1);
      padding: 40px;
    }
  }
  &__list {
    font-size: 0;
    margin-top: 73px;
    @media (--mobile) {
      margin-top: 50px;
    }
    li {
      font-size: 1.4rem;
      vertical-align: top;
      width: 420px;
      @media (--large) {
        display: inline-block;
      }
      @media (--mobile) {
        width: 100%;
      }
      + li {
        @media (--large) {
          margin-left: 120px;
        }
        @media (--mobile) {
          margin-top: 40px;
        }
      }
    }
    .c-btn {
      margin-top: 20px;
      width: 100%;
      a {
        font-size: 1.4rem;
        @media (--mobile) {
          font-size: 1.2rem;
        }
      }
    }
  }
}
