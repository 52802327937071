.p-interview {
  &__mv {
    height: 500px;
    overflow: hidden;
    @media (--mobile) {
      height: 260px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
  }
  &__morebtn {
    @media (--large) {
      letter-spacing: 0.1em;
      position: absolute;
      top: 10px;
      right: 0;
    }
    @media (--mobile) {
      font-size: 1.6rem;
      letter-spacing: 0.1em;
      text-align: center;
      width: 400px;
      @media (--mobile) {
        font-size: 1.2rem;
        font-weight: bold;
        width: 95%;
      }
      a {
        border: #cfcfcf 1px solid;
        display: block;
        padding: 8px 10px;
      }
      &--short {
        width: 240px;
      }
    }
  }
}
.interviewintro {
  margin: 0 auto;
  position: relative;
  padding-left: calc((100% - 960px) / 4);
  @media (--mobile) {
    background-color: $color-bgtalk;
    margin-bottom: -40px;
    padding-bottom: 40px;
    width: 100%;
  }
  &.inview {
    &::after {
      width: 0;
    }
  }
  &:before {
    background-color: $color-bgtalk;
    content: "";
    height: 360px;
    position: absolute;
    top: 0;
    width: 100%;
    @media (--large) {
      width: calc(100% - ((100% - 960px) / 4));
    }
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &::after {
    background-color: $color-bgGray;
    content: "";
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    transition: width .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--mobile) {
      width: 100%;
    }
  }
  &__inner {
    margin-left: calc((100vw - 960px) / 4);
    position: relative;
    padding: 70px 0;
    width: 960px;
    @media (--large) {
      display: flex;
    }
    @media (--mobile) {
      margin: auto;
      padding: 30px 0 20px;
      width: 84%;
    }
    p {
      color: #fff;
      font-size: 2rem;
      flex-grow: 1;
      letter-spacing: 0.3em;
      line-height: 2;
      @media (--mobile) {
        font-size: 1.5rem;
        line-height: 1.6;
        text-align: center;
      }
      small {
        @media (--mobile) {
          display: block;
          font-size: 1rem;
          margin-bottom: 5px;
        }
      }
    }
  }
  &__chapter {
    display: flex;
    font-size: 0;
    margin: 0 auto 40px;
    margin-left: calc((100vw - 960px) / 4);
    position: relative;
    width: 960px;
    @media (--mobile) {
      margin: 0 auto 20px;
      width: 84%;
    }
    &--bottom {
      @extend .interviewintro__chapter;
      margin: 0 auto 40px;
    }
    &--link {
      background-color: #9cc8d3;
      color: #83b7c5;
    }
    &--current {
      background-color: #fff;
      color: #83b7c5;
      font-weight: bold;
    }
    &--soon {
      background-color: #9cc8d3;
      color: #83b7c5;
    }

    li {
      font-size: 1.6rem;
      line-height: 35px;
      text-align: center;
      width: 50%;
      @media (--mobile) {
        font-size: 1.2rem;
        width: 50%;
      }
      + li {
        margin-left: 37px;
        @media (--mobile) {
          margin-left: 3%;
        }
      }
      a {
        border: #fff 1px solid;
        background-color: $color-bgtalk;
        color: #fff;
        display: block;
        padding: 0;
        &::before {
          background-color: #fff;
        }
        @media (--large) {
          &:hover {
            color: $color-lightBlue;
          }
        }
      }
    }


  }
}
.talkprofile {
  background-color: $color-bgtalk;
  margin-top: -120px;
  padding: 160px 0 40px;
  @media (--mobile) {
    margin-top: -65px;
    padding: 100px 0 40px;
  }
  &__inner {
    margin: 0 auto;
    width: 960px;
    @media (--mobile) {
      width: 92%;
    }
  }
  figure {
    @extend .clearfix;
    @media (--large) {
      display: flex;
    }
    figcaption {
      color: #fff;
      @media (--large) {
        margin-left: 30px;
        width: 710px;
      }
      dl {
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        + dl {
          margin-top: 40px;
        }
        dd {
          line-height: 2;
          margin-top: 20px;
          @media (--mobile) {
            line-height: 1.9;
            margin-top: 10px;
          }
          a {
            background: linear-gradient(transparent 95%, #fff 0%);
            background-position: 0% bottom;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: #fff;
          }
        }
      }
    }
  }
  &__photo {
    @media (--mobile) {
      float: left;
      margin-right: 10px;
      width: 140px;
    }
  }
  &__section{
    &:before {
      margin: 8px 0;
      width: 250px;
      content:'';
      display: block;
      border:1px solid #fff;
    }
    &:after {
      margin: 8px 0;
      width: 250px;
      content:'';
      display: block;
      border:1px solid #fff;
    }
  }
}
