.p-member {
  &__mv {
    height: 500px;
    overflow: hidden;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
  }
  &__morebtn {
    @media (--large) {
      letter-spacing: 0.1em;
      position: absolute;
      top: 10px;
      right: 0;
    }
    @media (--mobile) {
      font-size: 1.6rem;
      letter-spacing: 0.1em;
      text-align: center;
      width: 400px;
      @media (--mobile) {
        font-size: 1.2rem;
        font-weight: bold;
        width: 95%;
      }
      a {
        border: #cfcfcf 1px solid;
        display: block;
        padding: 8px 10px;
      }
      &--short {
        width: 240px;
      }
    }
  }
  &-textBox{
    padding-bottom: 30px;
    p+p{
      margin-top: 60px;
      @media (--mobile) {
        margin-top: 30px;
      }
    }

    &__title{
      color: $color-borderteam;
      position: relative;
      padding-left: 2.2em;
      &::before{
        content: "−";
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    &__lastText{
      font-size: 2rem;
    }

  }
}
.memberintro {
  margin: 0 auto;
  position: relative;
  @media (--mobile) {
    background-color: $color-bgteam;
    margin-bottom: -95px;
    width: 100%;
  }
  &.inview {
    &::after {
      width: 0;
    }
  }
  &:before {
    background-color: $color-bgteam;
    content: "";
    height: 640px;
    position: absolute;
    top: 0;
    left: calc((100% - 960px) / 4);
    width: 100%;
    @media (--large) {
      width: calc(100% - ((100% - 960px) / 4));
    }
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &::after {
    background-color: $color-bgGray;
    content: "";
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    transition: width .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--mobile) {
      width: 100%;
    }
  }
  &__inner {
    margin: 0 auto;
    position: relative;
    padding: 70px 0;
    width: 960px;
    @media (--large) {
      display: flex;
    }
    @media (--mobile) {
      padding: 50px 0 130px;
      text-align: center;
      width: 84%;
    }
    dt {
      color: #fff;
      font-size: 2rem;
      letter-spacing: 0.4em;
      line-height: 2;
      @media (--mobile) {
        font-size: 1.7rem;
        line-height: 1.6;
        text-align: center;
        letter-spacing: 0.3em;

      }
    }
    dd {
      color: #fff;
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 0.4em;
      @media (--mobile) {
        font-size: 1.7rem;
        line-height: 1.6;
        width: 100%;
      }
    }
  }
}
