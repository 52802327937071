
.mv {
  height: 100%;
  position: relative;
  &__ttl {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.8rem;
    font-weight: normal;
    letter-spacing: 0.1em;
    padding: 5px 40px;
    position: absolute;
    top: 17px;
    text-align: center;
    @media (--large) {
      right: -100%;
      transition: right 1s;
    }
    @media (--mobile) {
      top: 10px;
      left: -100%;
      padding: 2px 30px;
      transition: left 1s;
    }
    &.inview {
      @media (--large) {
        right: 0;
      }
      @media (--mobile) {
        left: 0;
      }
    }
    @at-root .p-lovot & {
      background-color: rgba(255, 255, 255, 0.2);
    }
    @at-root .p-team & {
      background-color: rgba(108, 100, 62, 0.5);
      @include z-contents(1);
    }
    @at-root .p-member & {
      background-color: rgba(108, 100, 62, 0.5);
      @include z-contents(1);
    }
    @at-root .p-interview & {
      background-color: transparent;
      padding: 0;
      text-align: right;
      @media (--mobile) {
        text-align: left;
      }
      span {
        background-color: rgba(0, 0, 0, 0.5);
        //line-height: 1.8;
        padding: 5px 40px;
        display: inline-block;
        margin-bottom: 1px;
        @media (--mobile) {
          padding: 2px 30px;
          line-height: 1.3;
        }
        small {
          display: inline-block;
          font-size: 1.6rem;
          line-height: 1;
          padding-left: 10px;
          margin-bottom: 2px;
          vertical-align: middle;
        }
      }
    }
  }
  &__photo {
    height: 110%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media (--large) {
      transition: top 1s;
    }
    @at-root .p-culture & {
      background: url(../images/culture/main.jpg) no-repeat center center;
      background-size: cover;
      @media (--mobile) {
        background-image: url(../images/culture/main_sp.jpg);
      }
    }
    @at-root .p-recruit & {
      background: url(../images/recruit/main.jpg) no-repeat center center;
      background-size: cover;
      @media (--mobile) {
        background-image: url(../images/recruit/main_sp.jpg);
      }
    }
    @at-root .p-about & {
      background: url(../images/about/main.jpg) no-repeat center center;
      background-size: cover;
      @media (--mobile) {
        background-image: url(../images/about/main_sp.jpg);
      }
    }
    @at-root .p-lovot & {
      background: url(../images/lovot/main.png) no-repeat center center;
      background-size: cover;
      @media (--mobile) {
        background: url(../images/lovot/main_sp.png) no-repeat center center;
        background-size: cover;
      }
    }
    @at-root .p-team & {
      background: url(../images/team/main.jpg) no-repeat center center;
      background-size: cover;
      @media (--mobile) {
        background-image: url(../images/team/main_sp.jpg);
      }
    }
    &--kato {
      @at-root .p-member & {
        background: url(../images/member/kato/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/kato/main_sp.jpg);
        }
      }
    }
    &--mukuda {
      @at-root .p-member & {
        background: url(../images/member/mukuda/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/mukuda/main_sp.jpg);
        }
      }
    }
    &--niwano {
      @at-root .p-member & {
        background: url(../images/member/niwano/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/niwano/main_sp.jpg);
        }
      }
    }
    &--nakazato {
      @at-root .p-member & {
        background: url(../images/member/nakazato/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/nakazato/main_sp.jpg);
        }
      }
    }
    &--shimizu {
      @at-root .p-member & {
        background: url(../images/member/shimizu/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/shimizu/main_sp.jpg);
        }
      }
    }
    &--sugiura {
      @at-root .p-member & {
        background: url(../images/member/sugiura/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/sugiura/main_sp.jpg);
        }
      }
    }
    &--yamada {
      @at-root .p-member & {
        background: url(../images/member/yamada/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/yamada/main_sp.jpg);
        }
      }
    }
    &--yoshida {
      @at-root .p-member & {
        background: url(../images/member/yoshida/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/member/yoshida/main_sp.jpg);
        }
      }
    }
    &--001 {
      @at-root .p-interview & {
        background: url(../images/interview/001/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/001/main_sp.jpg);
        }
      }
    }
    &--005 {
      @at-root .p-interview & {
        background: url(../images/interview/005/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/005/main_sp.jpg);
        }
      }
    }
    &--008 {
      @at-root .p-interview & {
        background: url(../images/interview/008/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/008/main_sp.jpg);
        }
      }
    }
    &--010 {
      @at-root .p-interview & {
        background: url(../images/interview/010/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/010/main_sp.jpg);
        }
      }
    }
    &--012 {
      @at-root .p-interview & {
        background: url(../images/interview/012/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/012/main_sp.jpg);
        }
      }
    }
    &--014 {
      @at-root .p-interview & {
        background: url(../images/interview/014/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/014/main_sp.jpg);
        }
      }
    }
    &--017 {
      @at-root .p-interview & {
        background: url(../images/interview/017/main.jpg) no-repeat center center;
        background-size: cover;
        @media (--mobile) {
          background-image: url(../images/interview/017/main_sp.jpg);
        }
      }
    }
  }
  &__moviebtn {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    margin: auto;
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    width: 625px;
    @media (--mobile) {
      bottom: 30px;
      padding: 8px;
      width: 315px;
    }
    @media (--large) {
      &:hover {
        div {
          &::before {
            width: 100%;
          }
        }
      }
    }
    div {
      background-color: $color-borderteam;
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: 0.1em;
      text-align: center;
      position: relative;
      padding: 8px 10px;
      @at-root .p-interview & {
        background-color: $color-bordertalk;
      }
      @media (--mobile) {
        font-size: 1.2rem;
      }
      &::before {
        background-color: $color-bgteam;
        height: 100%;
        @at-root .p-interview & {
          background-color: $color-lightBlue;
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        transition: width .8s;
      }
      span {
        position: relative;
        font-weight: bold;
        small {
          font-size: 1.2rem;
          font-weight: normal;
          @media (--mobile) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
