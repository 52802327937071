//for Vue.js

// hidden before compile
[v-cloak] {
  display: none;
}

.v-enter-active, .v-leave-active {
  transition: opacity 1s;
  opacity: 1;
}
.v-enter, .v-leave-to {
  opacity: 0;
}
