
.modal {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include z-modal(1);

  &__close {
    border: 1px solid #fff;
    cursor: pointer;
    height: 42px;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
    width: 42px;
    &::before {
      background-color: #fff;
      height: 2px;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: rotate(45deg);
      width: 20px;
    }
    &::after {
      background-color: #fff;
      height: 2px;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: rotate(-45deg);
      width: 20px;
    }
  }
  &__movie {
    height: 480px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 854px;
    @media (--mobile) {
      height: 200px;
      width: 300px;
    }

    &--center {
      @extend .modal__movie;
      width: 533px;
      @media (--mobile) {
        width: 95%;
      }
    }

    iframe {
      box-shadow: 0 0 100px rgba(0, 0, 0, 0.6);
      @media (--mobile) {
        height: 170px;
        width: 300px;
      }
    }
  }
  &__field {
    background-color: #fff;
    width:520px;
    min-height:116px;
    margin:0 0 0 auto ;
    padding: 20px;
    font-size:2.0rem;
    @media (--mobile) {
      width: 100%;
    }
  }
  &__label {
    margin-bottom:17px;
  }
  &__value {
    width: 100%;
    background-color: #f2f2f2;
    padding:10px 15px;
    font-size:1.6rem;
    & + & {
      margin-top: 15px;
    }
    @media (--mobile) {
      overflow:scroll;
    }
  }
}
