.p-about {
  &__mv {
    height: 500px;
    overflow: hidden;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
  }
}
.about {
  margin: 76px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 60px auto 0;
    width: 100%;
  }
  .p-about__ttl {
    text-align: center;
  }
  &__inner {
    margin-top: 40px;
    @media (--large) {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &__photo {
    @media (--mobile) {
      overflow: hidden;
      position: relative;
      text-align: center;
    }
  }
  &__caption {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.11em;
    margin-top: -10px;
    padding-right: 34px;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 10px auto 0;
      padding-right: 0;
      width: 84%;
    }
    p {
      + p {
        margin-top: 30px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
    }
  }
}
.aboutintro {
  margin: 0 auto;
  position: relative;
  padding-left: calc((100% - 960px) / 4);
  @media (--mobile) {
    background-color: $color-baseText;
    margin-bottom: -170px;
    padding-bottom: 170px;
    width: 100%;
  }
  &.inview {
    &::after {
      width: 0;
    }
  }
  &:before {
    background-color: $color-baseText;
    content: "";
    height: 570px;
    position: absolute;
    top: 0;
    width: 100%;
    @media (--large) {
      width: calc(100% - ((100% - 960px) / 4));
    }
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &::after {
    background-color: $color-bgGray;
    content: "";
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    transition: width .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--mobile) {
      width: 100%;
    }
  }
  &__inner {
    margin-left: calc((100vw - 960px) / 4);
    position: relative;
    padding: 70px 0 120px;
    text-align: center;
    width: 960px;
    @media (--mobile) {
      margin: auto;
      padding: 45px 0 55px;
      width: 90%;
    }
    h3 {
      color: #fff;
      font-size: 2.2rem;
      font-weight: normal;
      letter-spacing: 0.1em;
      margin-top: 24px;
      @media (--mobile) {
        font-size: 1.6rem;
        line-height: 1.8;
        margin-top: 5px;
      }
      + p {
        margin-top: 64px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
    }
    p {
      color: #fff;
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 0.1em;
      @media (--mobile) {
        font-size: 1.7rem;
        line-height: 1.6;
        text-align: center;
      }
      small {
        @media (--mobile) {
          display: block;
          font-size: 1rem;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.company {
  margin-top: 120px;
  @media (--mobile) {
    margin-top: 55px;
  }
  &__inner {
    margin: 0 auto;
    width: 960px;
    @media (--mobile) {
      margin: 0 0 0 auto;
      width: 92%;
    }
  }
  .p-about__ttl {
    margin-bottom: 40px;
    @media (--mobile) {
      margin-bottom: 30px;
      text-align: left;
    }
  }
  &__outline {
    border-top: $color-lightText 1px solid;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.1em;
    padding: 30px 0;
    position: relative;
    @media (--mobile) {
      font-size: 1.2rem;
      padding: 12px 0;
    }
    &:last-child {
      border-bottom: $color-lightText 1px solid;
    }
    &::before {
      background-color: $color-baseBlue;
      content: "";
      height: 98%;
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 5px;
    }
    dd {
      margin-top: 10px;
      position: relative;
      p {
        font-size: 1.4rem;
        @media (--large) {
          position: absolute;
          right: 80px;
          bottom: 0;
        }
        @media (--mobile) {
          margin-top: 15px;
        }
        a {
          @media (--mobile) {
            border: 1px solid #dadada;
            background-color: #fff;
            padding: 4px 20px;
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
