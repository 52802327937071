.p-talk {
  &__mv {
    height: 680px;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      padding-left: 20px;
    }
  }
}
.talksession {
  margin: 80px auto 0;
  position: relative;
  width: 960px;
  @at-root .p-interview & {
    margin: 45px auto 0;
  }
  @media (--mobile) {
    margin: 40px auto 0;
    width: 100%;
    @at-root .p-interview & {
      padding-bottom: 50px;
    }
  }
  &__info {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    line-height: 1.8;
    margin: 40px auto 30px;
    word-break: break-all;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 30px auto 20px;
      padding: 0 20px;
    }
  }
  &__inner {
    @at-root .p-top & {
      @media (--large) {
        position: relative;
        &::before {
          background-color: $color-bgGray;
          transition: width 1s ease .5s;
          @include inview-cover;
          @include z-contents(1);
          @media (--mobile) {
            background-color: #fff;
          }
        }
        &.inview {
          &::before {
            width: 0;
          }
        }
      }
    }
  }
  &__article {
    background-color: $color-bgtalk;
    margin-top: 20px;
    @media (--mobile) {
      margin-top: 30px;
    }
    + .talksession__article {
      @media (--mobile) {
        margin-top: 40px;
        @at-root .p-top & {
          margin-top: 30px;
        }
      }
    }
    figure {
      width: 100%;
      @media (--large) {
        display: table;
      }
    }
    figcaption {
      color: #fff;
      position: relative;
      @media (--large) {
        display: table-cell;
        text-align: center;
        //vertical-align: middle;
        padding-top: 95px;
      }
      @media (--mobile) {
        padding: 20px 20px 15px;
      }
    }
  }
  &__photo {
    @media (--large) {
      display: table-cell;
      vertical-align: middle;
      width: 640px;
    }
    @media (--mobile) {
      img {
        width: 100%;
      }
    }
  }
  &__vol {
    font-size: 2rem;
    @media (--mobile) {
      font-size: 1.4rem;
    }
    small {
      display: inline-block;
      font-size: 1.6rem;
      letter-spacing: 0.2em;
      vertical-align: middle;
      @media (--mobile) {
        font-size: 1.4rem;
      }
    }
    span {
      display: inline-block;
      letter-spacing: 0.1em;
      margin-left: 30px;
      vertical-align: middle;
      @media (--mobile) {
        margin-left: 20px;
      }
    }
  }
  &__volume{
    font-size: 1.6rem;
    letter-spacing: 1.1px;
  }
  &__caption {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    line-height: 1.7;
    margin: 5px 0;
    width: 100%;
    @media (--mobile) {
      font-size: 1.2rem;
      margin: 5px 0 15px;
    }
  }
  &__chapter {
    font-size: 0;
    letter-spacing: 0.1em;
    @media (--large) {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 77px;
    }
    li {
      color: #9cc8d3;
      font-size: 1.6rem;
      display: inline-block;
      @media (--mobile) {
        font-size: 1.2rem;
      }
      + li {
        margin-left: 15px;
        &::before {
          content: "/";
          padding-right: 15px;
        }
      }
      a {
        color: #9cc8d3;
      }
      a[href] {
        display: inline-block;
        color: #fff;
        position: relative;
        @media (--large) {
          &::after {
            background-color: #fff;
            height: 1px;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            transition: width .3s;
            width: 0;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__document{
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    @media (--large) {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 46px;
    }
    @media (--mobile) {
      padding-top: 15px;
      font-size: 1.1rem;
    }
    a {
      position: relative;
      color: #fff;
      &:after {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        transform-origin: center left ;
        transition: transform .3s;
      }
      &:hover {
        @media (--large) {
          &:after {
            transform: scale(0);
          }
        }
      }
    }
  }
  &__pager {
    font-size: 0;
    text-align: center;
    margin-top: 15px;
    @at-root .p-top & {
      text-align: left;
      margin-top: 20px;
      padding-left: 20px;
    }
    .swiper-pagination-bullet {
      background-color: $color-lightText;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      height: 7px;
      outline: none;
      width: 7px;
      &-active {
        background-color: $color-lightBlue;
      }
    }
  }
}
.talksecond {
  margin: 45px auto 0;
  position: relative;
  width: 960px;
  @media (--mobile) {
    margin: 40px auto 0;
    padding-bottom: 50px;
    width: 100%;
  }
  .p-interview__morebtn {
    display: none;
    @media (--mobile) {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 80%;
      a {
        background-color: $color-lightBlue;
        border: 0;
        color: #fff;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  &__navi {
    margin-top: 40px;
    position: relative;
    @media (--mobile) {
      margin-top: 15px;
    }
    @at-root .p-interview & {
      margin-top: 15px;
    }
  }
  &__arrow {
    cursor: pointer;
    outline: none;
    position: absolute;
    &--left {
      height: 40px;
      margin: auto;
      position: absolute;
      top: 0;
      left: -50px;
      bottom: 0;
      transition: left .3s;
      width: 40px;
      @include z-contents(1);
      @media (--large) {
        &:hover {
          left: -60px;
          &::after {
            animation: arrow-hover-left .8s;
          }
        }
      }
      &::before {
        background-color: $color-baseText;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 8px;
        bottom: 0;
        width: 25px;
      }
      &::after {
        background-color: $color-bgGray;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
      }
      i {
        border-left: 1px solid $color-baseText;
        border-bottom: 1px solid $color-baseText;
        display: block;
        height: 40px;
        transform: rotate(45deg);
        width: 40px;
        &::after {
          background-color: $color-baseText;
          height: 1px;
          content: "";
          margin: auto;
          position: absolute;
          top: 6px;
          right: 5px;
          transform: rotate(-45deg);
          width: 2px;
        }
      }
    }
    &--right {
      height: 40px;
      margin: auto;
      position: absolute;
      top: 0;
      right: -50px;
      bottom: 0;
      transition: right .3s;
      width: 40px;
      @include z-contents(1);
      @media (--large) {
        &:hover {
          right: -60px;
          &::after {
            animation: arrow-hover-right .8s;
          }
        }
      }
      &::before {
        background-color: $color-baseText;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 5px;
        bottom: 0;
        width: 25px;
      }
      &::after {
        background-color: $color-bgGray;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0;
      }
      i {
        border-top: 1px solid $color-baseText;
        border-right: 1px solid $color-baseText;
        display: block;
        height: 40px;
        transform: rotate(45deg);
        width: 40px;
        &::after {
          background-color: $color-baseText;
          height: 1px;
          content: "";
          margin: auto;
          position: absolute;
          left: 5px;
          bottom: 5px;
          transform: rotate(-45deg);
          width: 2px;
        }
      }
    }
  }
  &__list {
    @media (--large) {
      display: flex;
      @at-root .p-lovot & {
        flex-wrap: wrap;
      }
    }
  }
  &__listcont {
    background-color: #fff;
    padding: 5px;
    vertical-align: top;
    width: 318px;
    @media (--large) {
      height: auto;
      @at-root .p-lovot & {
        width: 296px;
      }
    }
    @media (--mobile) {
      //height: 140px;
      padding: 0;
      width: 100%;
    }
    &:nth-of-type(even) {
      @media (--mobile) {
        @at-root .p-top &  {
          margin-top: 6px;
        }
      }
    }
    + li {
      @at-root .p-interview & {
        @media (--large) {
          margin-left: 3px;
        }
        @media (--mobile) {
          margin-top: 6px;
        }
      }
    }
    &--pchide {
      @at-root .p-top &  {
        @media (--large) {
          display: none;
        }
      }
    }
    a[href] {
      display: block;
      transition: opacity .3s;
      &:hover {
        @media (--large) {
          opacity: .5;
        }
      }
    }
    figure {
      @media (--mobile) {
        background-color: $color-bgtalk;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    p {
      line-height: 1.5;
      letter-spacing: 0.1em;
      padding: 20px 20px;
      @media (--mobile) {
        color: #fff;
        font-size: 1.2rem;
        padding: 0;
        margin-top: 15px;
      }
      em {
        color: $color-lovotRed;
        font-style: normal;
      }
    }
    figcaption {
      @media (--mobile) {
        padding: 0 20px 0;
        width: 50%;
      }
    }
    &.no-images {
      .talksecond__listphoto {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include z-contents(0);
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  &__listphoto {
    height: 205px;
    overflow: hidden;
    position: relative;
    @media (--mobile) {
      height: 140px;
      width: 50%;
    }
    img {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      max-width: none;
      width: auto;
    }
  }
  &__listname {
    display: table;
    width: 100%;
    @media (--large) {
      background-color: $color-bgtalk;
      padding: 13px 20px;
    }
    dt {
      display: block;
      color: #fff;
      letter-spacing: 0.2em;
      @media (--large) {
        display: table-cell;
        //border-right: #fff 1px dotted;
        vertical-align: middle;
        width: 80px;
      }
      @media (--mobile) {
        font-size: 1.2rem;
        width: 65px;
      }
    }
    dd {
      display: block;
      margin-top: 8px;
      color: #fff;
      @media (--large) {
        display: table-cell;
        margin: 0;
        text-align: right;
        vertical-align: middle;
      }
      @media (--mobile) {
        font-size: 1.2rem;
      }
    }
  }
  &__lovotkeyword {
    display: none;
  }
  @at-root .p-top & {
    &__navi {
      &.inview {
        .talksecond__listcont {
          &::before {
            height: 0;
          }
          &::after {
            height: 0;
          }
        }
      }
    }
    &__listcont {
      position: relative;
      &::before {
        background-color: $color-lightBlue;
        transition: height .5s linear;
        @include inview-cover;
        @include z-contents(2);
      }
      &::after {
        background-color: $color-bgGray;
        transition: height .5s linear;
        @include inview-cover;
        @include z-contents(2);
      }
      &.index_0 {
        &::before {
          transition-delay: .5s;
        }
        &::after {
          transition-delay: 0s;
        }
      }
      &.index_1 {
        &::before {
          transition-delay: 1.5s;
        }
        &::after {
          transition-delay: 1s;
        }
      }
      &.index_2 {
        &::before {
          transition-delay: 1.7s;
        }
        &::after {
          transition-delay: 1.2s;
        }
      }
      &.index_3 {
        &::before {
          transition-delay: 1.9s;
        }
        &::after {
          transition-delay: 1.4s;
        }
      }
    }
  }
  @at-root .p-lovot & {
    &__navi {
      @media (--large) {
        border-top: $color-borderlovot 1px solid;
        padding: 35px;
      }
      @media (--mobile) {
      }
    }
    &__listphoto {
      height: 186px;
      @media (--mobile) {
        height: 140px;
      }
    }
    &__text {
      @media (--large) {
        display: none;
      }
    }
    &__lovotkeyword {
      @media (--large) {
        display: block;
      }
      letter-spacing: 0.1em;
      padding: 0 0 20px;
      @media (--mobile) {
        color: $color-baseText;
        font-size: 1.4rem;
        padding: 0 10px 5px
      }
      em {
        color: $color-lovotRed;
        font-style: normal;
      }
    }
  }
}
