/**
* Variables
*/
$fontfaceAlpha: "M+ 1p", "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3",
                 "メイリオ", Meiryo, "Helvetica Neue", Helvetica, Arial, sans-serif;


/** extend */
@media (--large) {
  //リンク時はhover opacity: .5
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear;
    &:hover {
      opacity: .5;
    }
  }
}
