
.pagelink {
  background-color: #fff;
  box-shadow: 0 2px 21px rgba(75, 33, 37, 0.1);
  margin: 120px auto;
  width: 960px;
  @media (--mobile) {
    margin: 40px auto 0;
    width: 90%;
  }
  &__inner {
    display: flex;
    padding: 40px;
    @media (--mobile) {
      padding: 20px 10px;
    }
  }
  &__article {
    width: 50%;
    & + & {
      margin-left: 35px;
      @media (--mobile) {
        margin-left: 10px;
      }
    }
  }
  a {
    display: block;
    @media (--large) {
      transition: opacity .3s;
      &:hover {
        opacity: .5;
      }
    }
  }
  figure {
    @media (--large) {
      display: flex;
    }
    figcaption {
      margin-top: 15px;
      @media (--large) {
        margin-left: 15px;
      }
      @media (--mobile) {
        margin-top: 10px;
      }
    }
  }
  dl {
    dt {
      font-size: 1.6rem;
      letter-spacing: 0.4em;
      @media (--mobile) {
        font-size: 1.3rem;
        letter-spacing: 0.2em;
      }
    }
    dd {
      font-size: 1.4rem;
      line-height: 1.8;
      margin-top: 15px;
      @media (--large) {
        letter-spacing: 0.08em;
      }
      @media (--mobile) {
        font-size: 1.3rem;
        line-height: 1.5;
        margin-top: 5px;
      }
      em {
        color: $color-lovotRed;
        font-style: normal;
      }
    }
  }
}
