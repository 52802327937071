.p-access {
  &__mv {
    height: 680px;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.2em;
    @media (--mobile) {
      font-size: 1.7rem;
      padding-left: 20px;
    }
  }
}
.access {
  margin: 80px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 0;
    padding: 40px 0 0;
    width: 100%;
  }
  &__inner {
    background-color: #fff;
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding: 40px 40px 80px;
    @media (--mobile) {
      margin: 35px auto 0;
      padding: 15px 15px 30px;
      width: 92%;
    }
    .map {
      background-color: #ccc;
      height: 400px;
      @media (--mobile) {
        height: 210px;
      }
      img {
        max-height: none;
        max-width: none;
      }
    }
    address {
      font-size: 1.6rem;
      font-style: normal;
      letter-spacing: 0.15em;
      line-height: 1.5;
      margin-top: 30px;
      position: relative;
      @media (--mobile) {
        font-size: 1.4rem;
        margin-top: 20px;
      }
    }
  }
  &__link {
    display: flex;
    @media (--large) {
      position: absolute;
      top: 0;
      right: 0;
    }
    @media (--mobile) {
      flex-wrap: wrap;
    }
    li {
      font-size: 1.4rem;
      @media (--mobile) {
        width: 48%;
      }
      + li {
        @media (--large) {
          margin-left: 15px;
        }
      }
      @media (--mobile) {
        &:first-child {
          display: block;
          margin-bottom: 10px;
          width: 100%;
          a {
            width: 100%;
          }
        }
        &:last-of-type {
          margin-left: 4%;
        }
      }
      a {
        box-sizing: border-box;
        border: 2px solid #f0f0f0;
        display: block;
        padding: 3px 0;
        text-align: center;
        position: relative;
        transition: color .8s;
        width: 100px;
        @media (--mobile) {
          font-size: 1.2rem;
          padding: 4px 0;
          width: auto;
        }
        &::before {
          background-color: $color-baseText;
          height: 100%;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          transition: width .8s;
        }
        @media (--large) {
          &:hover {
            color: #fff;
            &::before {
              width: 100%;
            }
          }
        }
        span {
          position: relative;
        }
      }
    }
    &--en {
      @extend .access__link;
      li {
        @media (--mobile) {
          width: 100%;
          &:last-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
  &__name {
    @media (--mobile) {
      margin: 40px auto 0;
      width: 90%;
    }
  }
  &__address {
    margin-top: 25px;
    @media (--mobile) {
      margin: 25px auto 0;
      line-height: 2;
      width: 90%;
    }
  }
  &__tel {
    @media (--mobile) {
      margin: 15px auto 0;
      width: 90%;
    }
  }
  &__detail {
    line-height: 1.8;
    letter-spacing: 0.1em;
    margin-top: 20px;
    @media (--mobile) {
      font-size: 1rem;
      line-height: 1.7;
      margin: 30px auto 0;
      width: 90%;
    }
    ul {
      li {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
  &__photo {
    font-size: 0;
    margin-top: 30px;
    @media (--mobile) {
      margin-top: 25px;
    }
    li {
      @media (--large) {
        display: inline-block;
        vertical-align: top;
        width: 280px;
      }
      + li {
        @media (--large) {
          margin-left: 20px;
        }
        @media (--mobile) {
          margin-top: 20px;
        }
      }
      figure {
        img {
          width: 280px;
          @media (--mobile) {
            max-height: none;
            width: 100%;
          }
        }
        figcaption {
          font-size: 1.4rem;
          letter-spacing: 0.15em;
          line-height: 1.5;
          margin-top: 10px;
          @media (--mobile) {
            font-size: 1rem;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
