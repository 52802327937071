.p-culture {
  &__mv {
    height: 500px;
    overflow: hidden;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
  }
  &__catch {
    font-size: 2rem;
    letter-spacing: 0.4em;
    margin-top: 35px;
    @media (--mobile) {
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      text-align: center;
    }
    &--en {
      @extend .p-culture__catch;
      font-size: 1.8rem;
      line-height: 1.8;
      letter-spacing: 0.1em;
    }
  }
  &__text {
    background: url(../images/culture/pix.png) no-repeat 98% top;
    font-size: 1.6rem;
    hyphens: auto;
    line-height: 2;
    letter-spacing: 0.12em;
    margin-top: 30px;
    @media (--large) {
      padding-right: 270px;
    }
    @media (--mobile) {
      background: url(../images/culture/pix.png) no-repeat center top;
      font-size: 1.4rem;
      padding-top: 230px;
      margin: 30px auto 0;
      width: 84%;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
  }
  &__photo {
    margin-top: 70px;
  }
}
.culture {
  margin: 76px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 60px auto 0;
    width: 100%;
  }
  .p-culture__ttl {
    letter-spacing: 0.4em;
    @media (--mobile) {
      letter-spacing: 0.2em;
    }
  }
  &__inner {
    margin-top: 40px;
    @media (--large) {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &__photo {
    @media (--mobile) {
      height: 230px;
      overflow: hidden;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
  &__caption {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.11em;
    margin-top: -10px;
    padding-right: 35px;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 10px auto 0;
      padding-right: 0;
      width: 84%;
    }
    p {
      + p {
        margin-top: 30px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
    }
  }
}
.tradition {
  margin: 65px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 50px auto 0;
    width: 100%;
  }
  .p-culture__ttl {
    text-align: center;
    @media (--mobile) {
      text-align: left;
      padding-left: 20px;
    }
  }
  &__cont {
    position: relative;
    margin-top: 15px;
    width: 100%;
    + .tradition__cont {
      margin-top: 30px;
      @media (--mobile) {
        margin-top: 20px;
      }
    }
  }
  &__slide {
    height: 480px;
    @media (--mobile) {
      height: 375px;
      width: 100%;
    }
  }
  &__photo {
    @media (--mobile) {
      width: 100%;
      picture {
        display: block;
        height: 375px;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
      img {
        max-height: none;
        max-width: none;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @media (--large) {
          min-height: 100%;
          min-width: 100%;
        }
        @media (--mobile) {
          max-height: none;
          max-width: none;
          width: 100%;
        }
      }
    }
  }
  &__text {
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 1.6rem;
    hyphens: auto;
    line-height: 2;
    letter-spacing: 0.12em;
    margin: -40px auto 0;
    position: relative;
    padding: 30px 40px 40px;
    width: 900px;
    @include z-contents(1);
    @media (--mobile) {
      font-size: 1.4rem;
      padding: 12px 15px 15px;
      width: 92%;
    }
  }
}
.timer {
  height: 120px;
  margin: auto;
  position: absolute;
  top: 190px;
  left: 0;
  right: 0;
  width: 120px;
  @include z-contents(1);
  @media (--mobile) {
    top: 140px;
  }
  &__circle{
    height: 120px;
    width: 120px;
    display: block;
    position: relative;
    svg {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      transform: rotate(-90deg);
      top: 0;
      left: 0;
      overflow: visible;
    }
    circle {
      fill: rgba(0,0,0,0);
      stroke: rgba(255,255,255,0.4);
      stroke-width: 20;
      stroke-dasharray: 315;
      stroke-dashoffset: 315;
    }
    .timerSet {
    //   animation: rotate 10s linear infinite;
      circle {
    //    animation: stroke 10s linear infinite;
        stroke: rgba(255,255,255,0.4);
        stroke-dasharray: 315;
      }
    }
  }
  &__parts {
    background-color: #fff;
    border-radius: 50%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    justify-content: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 100px;
    div {
      text-align: center;
    }
  }
  &__ttl {
    font-size: 1.8rem;
    letter-spacing: 0.1em;
    width: 100%;
    &--en {
      @extend .timer__ttl;
      font-size: 1.6rem;
    }
  }
}
.timer--sp{
  position: absolute;
  top: 20px;
  left: 0;
  @include z-contents(1);
  background: #000;
  color: #fff;
  p{
    position: relative;
    padding:6px 20px;
    font-size: 1.6rem;
  }
  span{
    background-color: #333;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .5s linear;
    width: 0%;
    // transform: scale(1,1);
    // transform-origin: left;
  }
}
