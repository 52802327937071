/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */

/**
 * for mobile viewoport
 */
@custom-media --mini2 (max-width: 360px);
@custom-media --mini (max-width: 450px);
@custom-media --mobile (max-width: 1023px);
/**
 * for tablet and Pc. large viewport
 */
@custom-media --tablet (max-width: 1135px);
@custom-media --large (min-width: 1024px);

//@custom-media --phone (min-width: 544px);
// @custom-media --tablet (min-width: 768px);
// @custom-media --desktop(min-width: 992px);
