/**
 * main.scss
 */
::selection {
  background: $color-bgtalk;
  color: #fff;
  @at-root .p-team & {
    background: $color-bgteam;
  }
  @at-root .p-member & {
    background: $color-bgteam;
  }
}
.l-main {
  font-size: 1.4rem;
  line-height: 1.2;
  padding-bottom: 120px;
  padding-top: 80px;
  @media (--mobile) {
    padding: 0 env(safe-area-inset-right) 60px env(safe-area-inset-left);
    padding-top: 55px;
    padding-bottom: 60px;
  }
  @at-root .p-top & {
    background-color: $color-bgGray;
    overflow: hidden;
    padding-top: 0;
    @media (--mobile) {
      padding-top: 55px;
      padding-bottom: 0;
    }
  }
  @at-root .p-about & {
    background-color: $color-bgGray;
  }
  @at-root .p-news & {
    @media (--mobile) {
      background-color: $color-bgGray;
      padding-bottom: 0;
    }
  }
  @at-root .p-document & {
    background-color: $color-bgGray;
  }
  @at-root .p-access & {
    background-color: $color-bgGray;
  }
  @at-root .p-contact & {
    background-color: $color-bgGray;
  }
  @at-root .p-lovot & {
    background-color: $color-bglovot;
    @media (--mobile) {
      padding-bottom: 0;
    }
  }
  @at-root .p-team & {
    background-color: $color-bgGray;
    overflow: hidden;
    @media (--mobile) {
      padding-bottom: 0;
    }
  }
  @at-root .p-member & {
    background-color: $color-bgGray;
    overflow: hidden;
  }
  @at-root .p-talk & {
    @media (--mobile) {
      padding-bottom: 0;
    }
  }
  @at-root .p-interview & {
    background-color: $color-bgGray;
    overflow: hidden;
  }
  @at-root .p-misstype & {
    background-color: $color-bgGray;
  }
  @at-root .p-404 & {
    background-color: $color-bgGray;
  }
  @at-root .p-coming & {
    @media (--mobile) {
      padding-top: 0;
    }
  }
  @at-root .p-privacy & {
    background-color: $color-bgGray;
  }
  @at-root .p-recruit & {
    padding-bottom: 0;
  }
  &--en {
    @extend .l-main;
    @at-root .p-lovot & {
      background-color: $color-bglovot;
      @media (--mobile) {
        padding-bottom: 60px;
      }
    }
  }
}
