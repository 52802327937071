
.mvslide {
  height: 100%;
  @at-root .p-top & {
    overflow: hidden;
    position: relative;
    &::before {
      background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
      content: "";
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include z-contents(5);
      @media (--mobile) {
        height: 100px;
      }
    }
  }
  &__slide {
    height: 100%;
    width: 100%;
    &.mv-slide-active,
    &.mv-slide-duplicate-active {
      .mvslide__photo {
        transform: scale(1) translate3d(0, 0, 0);
      }
      .mvslide__caption {
        &::before {
          @media (--large) {
            width: 100%;
          }
        }
        strong {
          bottom: 0;
        }
        span {
          bottom: 0;
        }
      }
    }
    &.mv-slide-prev {
      .mvslide__photo {
        transform: scale(1) translate3d(0, 0, 0);
        transition: transform 0s ease-out, opacity 0s;
      }
      .mvslide__caption {
        &::before {
          transition: width 0s linear 0s;
          width: 0;
        }
        strong {
          bottom: -60px;
        }
        span {
          bottom: -60px;
        }
      }
    }
    a {
      display: block;
    }
  }
  &__photo {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    transform: scale(1.1) translate3d(0, 0, 0);
    transition: transform 2s ease-out, opacity 1s;
    height: 100%;
    width: 100%;
  }
  &__caption {
    color: #fff;
    padding: 15px 35px;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: clip .8s ease, opacity 1s;
    @media (--large) {
      background-color: #000;
    }
    @media (--mobile) {
      padding: 0 0 60px 0;
      text-align: right;
    }
    &::before {
      background-color: $color-baseText;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: width 4s linear 2s;
      width: 0;
    }
    p {
      display: inline-block;
      @media (--large) {
        vertical-align: middle;
      }
      &.gotham {
        @media (--large) {
          margin-right: 25px;
        }
      }
    }
    strong {
      display: inline-block;
      letter-spacing: 0.1em;
      font-size: 2.4rem;
      font-weight: normal;
      position: relative;
      bottom: -60px;
      transition: bottom .5s ease;
      vertical-align: middle;
      @media (--mobile) {
        background-color: #000;
        font-size: 2rem;
        margin-bottom: 5px;
        padding: 2px 20px;
      }
    }
    span {
      display: inline-block;
      letter-spacing: 0.1em;
      font-size: 1.6rem;
      position: relative;
      bottom: -60px;
      transition: bottom .5s ease;
      vertical-align: middle;
      @media (--mobile) {
        background-color: #000;
        display: inline;
        font-size: 1.2rem;
        line-height: 2;
        padding: 5px 20px;
      }
    }
  }
  &__pager {
    background-color: #000;
    display: flex;
    height: 30px;
    position: absolute;
    left: 0;
    text-align: center;
    transition: bottom .5s ease 1s;
    @include z-contents(1);
    @media (--large) {
      bottom: -30px;
      @at-root .p-team & {
        left: 120px;
        bottom: 0;
      }
    }
    @media (--tablet) {
      @at-root .p-team & {
        left: 0;
      }
    }
    @media (--mobile) {
      height: 25px;
      left: auto;
      right: 0;
      bottom: -25px;
      @at-root .p-team & {
        left: auto !important;
      }
    }
    &.inview {
      @media (--large) {
        bottom: 0;
        @at-root .p-team & {
          bottom: 29px;
        }
      }
      @media (--mobile) {
        bottom: 10px;
        @at-root .p-team & {
          bottom: 0;
        }
      }
    }
    &--team {
      left: 120px;
      bottom: 29px;
      @media (--tablet) {
        left: 0;
      }
      @media (--mobile) {
        left: auto !important;
        bottom: 0;
      }
    }
    .prev {
      cursor: pointer;
      height: 100%;
      margin-right: 15px;
      position: relative;
      width: 30px;
      &::before {
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        content: "";
        height: 7px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: rotate(45deg);
        width: 7px;
      }
      @media (--large) {
        transition: background-color .3s;
        &:hover {
          background-color: $color-baseText;
        }
      }
    }
    .numb {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: #666;
      display: flex;
      font-size: 1.2rem;
      height: 100%;
      width: 30px;
      &.is-curr {
        color: #fff;
      }
      @media (--large) {
        transition: background-color .3s;
        &:hover {
          background-color: $color-baseText;
        }
      }
    }
    .next {
      cursor: pointer;
      height: 100%;
      margin-left: 15px;
      position: relative;
      width: 30px;
      &::before {
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        content: "";
        height: 7px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: rotate(45deg);
        width: 7px;
      }
      @media (--large) {
        transition: background-color .3s;
        &:hover {
          background-color: $color-baseText;
        }
      }
    }
  }
}
