.p-team {
  &__mv {
    height: 500px;
    overflow: hidden;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    @media (--mobile) {
      font-size: 1.7rem;
      text-align: center;
    }
  }
}
.teamintro {
  margin: 0 auto;
  position: relative;
  @media (--mobile) {
    background-color: $color-bgteam;
    margin-bottom: -95px;
    width: 100%;
  }
  &.inview {
    &::after {
      width: 0;
    }
  }
  &:before {
    background-color: $color-bgteam;
    content: "";
    height: 620px;
    position: absolute;
    top: 0;
    left: calc((100% - 960px) / 4);
    width: 100%;
    @media (--large) {
      width: calc(100% - ((100% - 960px) / 4));
    }
    @media (--tablet) {
      left: 0;
      width: 100%;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &::after {
    background-color: $color-bgGray;
    content: "";
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    transition: width .6s ease .5s;
    width: calc(100% - ((100% - 960px) / 4));
    @include z-contents(1);
    @media (--mobile) {
      width: 100%;
    }
  }
  .p-about__ttl {
    text-align: center;
  }
  &__inner {
    margin: 0 auto;
    position: relative;
    padding: 80px 0;
    width: 960px;
    @media (--large) {
      display: flex;
      flex-direction: row-reverse;
    }
    @media (--mobile) {
      padding: 50px 0 130px;
      width: 84%;
    }
  }
  &__photo {
    margin-top: 10px;
    @media (--mobile) {
      overflow: hidden;
      position: relative;
      text-align: center;
    }
  }
  &__caption {
    color: #fff;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.11em;
    margin-top: -10px;
    padding-right: 34px;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 10px auto 0;
      padding-right: 0;
    }
    p {
      + p {
        margin-top: 30px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
    }
  }
}
.memberlist {
  margin: 120px auto 0;
  position: relative;
  width: 960px;
  @media (--mobile) {
    margin: 60px auto 0;
    width: 100%;
    @at-root .p-member & {
      padding-bottom: 50px;
    }
  }
  .p-member__morebtn {
    @media (--mobile) {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 80%;
      a {
        background-color: $color-borderteam;
        border: 0;
        color: #fff;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  &__navi {
    margin-top: 15px;
    position: relative;
    @media (--mobile) {
      margin-top: 20px;
    }
  }
  &__arrow {
    cursor: pointer;
    outline: none;
    position: absolute;
    &--left {
      border-left: 1px solid $color-baseText;
      border-bottom: 1px solid $color-baseText;
      height: 40px;
      margin: auto;
      position: absolute;
      top: 0;
      left: -50px;
      bottom: 0;
      transform: rotate(45deg);
      transition: left .3s;
      width: 40px;
      &::before {
        background-color: $color-baseText;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 8px;
        bottom: 0;
        transform: rotate(-45deg);
        width: 25px;
      }
      &::after {
        background-color: $color-baseText;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 8px;
        right: 6px;
        transform: rotate(-45deg);
        width: 2px;
      }
      &:hover {
        left: -60px;
      }
    }
    &--right {
      border-top: 1px solid $color-baseText;
      border-right: 1px solid $color-baseText;
      height: 40px;
      margin: auto;
      position: absolute;
      top: 0;
      right: -50px;
      bottom: 0;
      transform: rotate(45deg);
      transition: right .3s;
      width: 40px;
      &::before {
        background-color: $color-baseText;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        transform: rotate(-45deg);
        width: 25px;
      }
      &::after {
        background-color: $color-baseText;
        height: 1px;
        content: "";
        margin: auto;
        position: absolute;
        left: 8px;
        bottom: 6px;
        transform: rotate(-45deg);
        width: 2px;
      }
      &:hover {
        right: -60px;
      }
    }
  }
  &__list {
    @media (--large) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__listcont {
    background-color: #fff;
    height: auto;
    padding: 5px;
    vertical-align: top;
    width: 318px;
    @media (--mobile) {
      padding: 0;
      width: 100%;
    }
    + li {
      @media (--mobile) {
        margin-top: 6px;
      }
    }
    &:nth-of-type(3n - 1) {
      @media (--large) {
        margin: 0 3px;
      }
    }
    &:nth-of-type(n + 4) {
      @media (--large) {
        margin-top: 3px;
      }
    }
    figure {
      @media (--mobile) {
        background-color: $color-borderteam;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    figcaption {
      @media (--mobile) {
        background-color: #fff;
        padding: 10px 10px 25px;
        width: 100%;
      }
      p {
        line-height: 1.5;
        letter-spacing: 0.1em;
        padding: 20px 20px;
        @media (--mobile) {
          font-size: 1.2rem;
          padding: 0;
        }
      }
    }
  }
  &__listphoto {
    background-position: center center;
    background-size: cover;
    height: 205px;
    overflow: hidden;
    position: relative;
    @media (--mobile) {
      height: 140px;
      width: 50%;
    }
  }
  &__profile {
    background-color: $color-borderteam;
    @media (--large) {
      padding: 5px;
    }
    @media (--mobile) {
      width: 50%;
    }
  }
  &__listname {
    @media (--large) {
      background-color: $color-borderteam;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      width: 100%;
    }
    @media (--mobile) {
      padding: 0px 10px 0;
      height: 65px;
    }
    dt {
      color: #fff;
      letter-spacing: 0.2em;
      line-height: 1.3;
      @media (--large) {
        font-size: 1.2rem;
        position: relative;
        padding-right: 15px;
        border-right: #fff 1px dotted;
        height: 36px;
        display: flex;
        align-items: center;
      }
      @media (--mobile) {
        font-size: 1rem;
        padding-top: 1px;
      }
    }
    dd {
      color: #fff;
      @media (--large) {
        text-align: right;
        flex-grow: 1;
      }
      @media (--mobile) {
        font-size: 1.2rem;
        margin-top: 9px;
      }
    }
  }
  &__item {
    @media (--large) {
      border-top: $color-bgteam 1px solid;
      display: flex;
      margin-top: 5px;
      padding-top: 5px;
    }
    @media (--mobile) {
      margin: 0 8px 0;
    }
    li {
      font-weight: bold;
      letter-spacing: 0.2em;
      text-align: center;
      @media (--large) {
        width: 50%;
      }
      @media (--mobile) {
        font-size: 1.2rem;
      }
      + li {
        @media (--large) {
          margin-left: 3px;
        }
      }
      a {
        background-color: $color-bgteam;
        display: block;
        padding: 8px 10px;
        position: relative;
        @media (--large) {
          color: #e5e0cb;
        }
        @media (--mobile) {
          border: $color-borderteam 1px solid;
          color: #fff;
          padding: 7px 10px;
        }
        &::before {
          background-color: $color-borderteam;
          height: 100%;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          transition: width .8s;
        }
        @media (--large) {
          &:hover {
            &::before {
              width: 100%;
            }
          }
        }
        span {
          position: relative;
        }
      }
    }
  }
}
