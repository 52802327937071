.c-link {
  position: relative;
  color: #fff;
  text-decoration: none;
  background:none !important;
  &:after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    transform-origin: center left ;
    transition: transform .3s;
  }
  &:hover {
    @media (--large) {
      &:after {
         transform: scale(0);
      }
    }
  }
}
