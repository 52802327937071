.p-contact {
  &__mv {
    height: 680px;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.2em;
    @media (--mobile) {
      font-size: 1.7rem;
    }
  }
}
.contact {
  margin: 80px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 0 auto;
    padding: 40px 0 0;
    width: 90%;
  }
  &__inner {
    background-color: #fff;
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding: 35px 40px 40px;
    @media (--mobile) {
      padding: 20px;
      margin-top: 25px;
    }
    .need {
      color: #af101c;
      @media (--mobile) {
        font-size: 1rem;
      }
    }
  }
  &__text {
    background-color: $color-bgGray;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.15em;
    padding: 20px;
    text-align: center;
    @media (--mobile) {
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      padding: 20px 10px;
    }
  }
  &__btnarea {
    font-size: 0;
    margin-top: 40px;
    text-align: center;
    @media (--mobile) {
      margin-top: 0;
    }
    li {
      letter-spacing: 0.05em;
      font-size: 1.6rem;
      @media (--large) {
        display: inline-block;
      }
      @media (--mobile) {
        margin-top: 20px;
      }
      &:nth-of-type(even) {
        @media (--large) {
          margin-left: 80px;
        }
      }
      &:nth-of-type(n + 3) {
        @media (--large) {
          margin-top: 40px;
        }
      }
      a {
        background-color: $color-baseBlue;
        border: 0;
        cursor: pointer;
        color: #fff;
        display: inline-block;
        margin: 0 auto;
        outline: 0;
        padding: 10px;
        position: relative;
        transition: color .3s;
        width: 400px;
        &::before {
          background-color: $color-bgGray;
          height: 100%;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          transition: width .8s;
        }
        @media (--large) {
          &:hover {
            color: $color-baseBlue;
            &::before {
              width: 100%;
            }
          }
        }
        span {
          position: relative;
        }
        @media (--mobile) {
          font-size: 1.2rem;
          width: 100%;
        }
      }
    }
  }
  &__att {
    letter-spacing: 0.1em;
    margin-top: 15px;
    text-align: right;
    @media (--mobile) {
      font-size: 1.2rem;
    }
  }
}
