
.c-btn {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  width: 400px;
  @media (--mobile) {
    font-size: 1.3rem;
    width: 95%;
  }
  a {
    border: #cfcfcf 1px solid;
    display: block;
    padding: 8px 10px;
    position: relative;
    transition: color .8s;
    &::before {
      background-color: $color-baseText;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      transition: width .8s;
    }
    @media (--large) {
      &:hover {
        color: #fff;
        &::before {
          width: 100%;
        }
      }
    }
    span {
      position: relative;
    }
  }
  &--short {
    width: 240px;
  }
}
.c-btn__arrow {
  letter-spacing: 0.1em;
  a {
    position: relative;
    padding-left: 20px;
    &::before {
      background: $color-baseText;
      content: "";
      height: 1px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transition: left .3s;
      width: 8px;
    }
    &::after {
      border-top: 1px solid $color-baseText;
      border-right: 1px solid $color-baseText;
      content: "";
      height: 7px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 4px;
      bottom: 0;
      transform: rotate(45deg);
      transition: left .3s;
      width: 7px;
    }
    &:hover {
      @media (--large) {
        &::before {
          left: 5px;
        }
        &::after {
          left: 9px;
        }
      }
    }
  }
}
.c-btn__circle {
  span {
    position: relative;
    padding-left: 30px;
    @media (--mobile) {
      padding-left: 22px;
    }
    &::before {
      border-radius: 50%;
      background: #fff;
      content: "";
      height: 14px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 14px;
      @media (--mobile) {
        height: 12px;
        width: 12px;
      }
    }
    &::after {
      border: 4px solid transparent;
      border-left: 4px solid $color-borderteam;
      box-sizing: border-box;
      content: "";
      height: 4px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 6px;
      bottom: 0;
      width: 4px;
      @at-root .p-interview & {
        border-left: 4px solid $color-bordertalk;
      }
      @media (--mobile) {
        border: 3px solid transparent;
        border-left: 3px solid $color-borderteam;
        height: 3px;
        left: 5px;
        width: 3px;
        @at-root .p-interview & {
          border-left: 3px solid $color-bordertalk;
        }
      }
    }
  }
}
.c-btn__line {
  a {
    background: linear-gradient(transparent 95%, #000 0%);
    background-position: 0% bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 2px;
    transition: background-size .3s;
    @media (--large) {
      &:hover {
        background-size: 0 100%;
      }
    }
  }
}

