
.l-header {
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  position: absolute;
  top: 0;
  left: 0;
  transition: top 1s;
  width: 100%;
  @include z-header(1);
  @media (--mobile) {
    background-color: #fff;
  }
  &::before {
    background-color: rgba(0, 0, 0, 0.1);
    content: "";
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: height 1s ease, opacity 2s .5s;
    width: 100%;
  }
  &.fixed {
    background-color: #fff;
    position: fixed;
  }
  &__head {
    position: relative;
    transition: padding 1s ease;
    @media (--large) {
      padding-top: 20px;
    }
    &.inview {
      @media (--large) {
        padding-top: 0;
      }
      .menu {
        top: 13px;
        @at-root .p-top & {
          top: 26px;
        }
        @at-root .p-top .fixed & {
          top: 13px;
        }
      }
    }
    @media (--large) {
      height: 80px;
    }
  }
  &__top {
    &.leave {
      &::before {
        opacity: .5;
        height: 80px;
      }
    }
    .l-header__head {
      @media (--large) {
        padding-top: 20px;
      }
      @media (--tablet) {
        padding-top: 0;
      }
    }
    .head {
      padding: 10px 20px;
      @media (--mobile) {
        padding: 10px;
      }
    }
    .submenu {
      &__btn {
        a {
          color: #fff;
          &::before {
            background-color: #fff;
          }
        }
        &--twitter {
          a {
            &:before {
              display: none;
            }
          }
          .plane {
            fill: #fff;
          }
        }
        &--facebook {
          a {
            &:before {
              display: none;
            }
          }
          .plane {
            fill: #fff;
          }
        }
      }
    }
    .mainmenu {
      &__btn {
        a {
          color: #fff;
          &::before {
            background-color: #fff;
          }
          &:hover {
            &::after {
              border-color: transparent transparent #ffffff transparent;
            }
          }
        }
      }
      &__sub {
        border: 0;
        background-color: transparent;
        top: 87px;
        li {
          a {
            color: #fff;
          }
        }
      }
    }
  }
}
.head {
  backface-visibility: hidden;
  font-size: 0;
  padding: 16px 20px;
  transition: margin-left 1s .5s, padding 1s;
  width: 290px;
  @at-root .p-top & {
    background-color: #fff;
    @media (--large) {
      margin-left: -300px;
    }
  }
  @at-root .p-top .inview & {
    @media (--large) {
      margin-left: 0;
    }
  }
  @media (--mobile) {
    padding: 10px;
  }
  &__logo {
    display: inline-block;
    height: 47px;
    vertical-align: middle;
    width: 161px;
    @media (--mobile) {
      height: 35px;
      width: 118px;
    }
  }
  &__language {
    color: $color-lightText;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 37px;
    vertical-align: middle;
    @media (--mobile) {
      display: none;
    }
    a,
    span {
      color: $color-lightText;
      &.is-curr {
        color: $color-baseText;
      }
    }
    a[href] {
      @media (--large) {
        transition: opacity .3s;
        &:hover {
          opacity: .5;
        }
      }
    }
  }
}
.menu {
  margin-right: 20px;
  text-align: right;
  position: absolute;
  top: 26px;
  transition: top 1s ease;
  right: 0;
  @at-root .p-top & {
    @media (--large) {
      transition: clip 1s;
    }
  }
  @media (--tablet) {
    display: none;
  }
}
.menubtn {
  display: inline-block;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 50px;
  @include z-header(1);
  @media (--large) {
    display: none;
  }
  @media (--tablet) {
    display: flex;
  }
  @media (--mobile) {
    height: 50px;
    top: 3px;
    right: 0;
    width: 50px;
  }
  &.active {
    background-color: #222;
    height: 50px;
    top: 10px;
    right: 20px;
    right: calc(env(safe-area-inset-right) + 10px);
    width: 50px;
    @media (--mobile) {
      top: 10px;
      right: 10px;
    }
    .menubtn__border {
      height: 19px;
      width: 19px;
      @media (--mobile) {
        height: 11px;
        width: 11px;
      }
      span {
        background-color: #fff;
        height: 1px;
        &:nth-of-type(1) {
          top: 9px;
          transform: rotate(45deg);
          @media (--mobile) {
            top: 5px;
          }
        }
        &:nth-of-type(2) {
          bottom: 9px;
          transform: rotate(-45deg);
          @media (--mobile) {
            bottom: 5px;
          }
        }
      }
    }
  }
  &__border {
    height: 12px;
    position: relative;
    width: 20px;
    @media (--mobile) {
      height: 8px;
      width: 24px;
    }
    span {
      display: inline-block;
      box-sizing: border-box;
      background-color: #444;
      height: 2px;
      position: absolute;
      left: 0;
      transition: transform .3s;
      width: 100%;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        bottom: 0;
      }
    }
  }
}
.submenu {
  font-size: 0;
  &__btn {
    display: inline-block;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    vertical-align: middle;
    + .submenu__btn {
      margin-left: 15px;
    }
    a {
      display: block;
      position: relative;
      color: #666;
      &::before {
        background-color: #333;
        height: 1px;
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        transition: width .3s;
        width: 0;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
    @at-root .header-curr-sub__01 & {
      &:nth-of-type(1){
        a {
          &::before {
            width: 100%;
          }
        }
      }
    }
    @at-root .header-curr-sub__02 & {
      &:nth-of-type(2){
        a {
          &::before {
            width: 100%;
          }
        }
      }
    }
    @at-root .header-curr-sub__03 & {
      &:nth-of-type(3){
        a {
          &::before {
            width: 100%;
          }
        }
      }
    }
    &--twitter {
      a {
        transition: opacity .3s;
        &:before {
          display: none;
        }
        &:hover {
          @media (--large) {
            opacity: .5;
          }
        }
      }
      + .submenu__btn--facebook {
        margin-left: 10px;
      }
      svg {
        height: 14px;
        width: 17px;
      }
      .plane {
        fill: #333;
      }
    }
    &--facebook {
      a {
        transition: opacity .3s;
        &:before {
          display: none;
        }
        &:hover {
          @media (--large) {
            opacity: .5;
          }
        }
      }
      svg {
        height: 14px;
        width: 12px;
      }
      .plane {
        fill: #333;
      }
    }
  }
}
.mainmenu {
  font-size: 0;
  margin-top: 16px;
  &__btn {
    display: inline-block;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    padding-bottom: 20px;
    position: relative;
    + .mainmenu__btn {
      margin-left: 35px;
    }
    a {
      transition: color 2s;
    }
    &.hover {
      @media (--large) {
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    a {
      display: block;
      position: relative;
      &::before {
        background-color: #333;
        height: 1px;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        transition: width .3s;
        width: 0;
      }
      &:hover {
        &::before {
          width: 100%;
          @include z-header(1);
        }
      }
    }
    @at-root .header-curr__01 .l-header__top & {
      &:nth-of-type(1) {
        @include header-arrow();
        &::before {
          display: none;
        }
        &::after {
          border-color: transparent transparent #fff transparent;
        }
      }
    }
    @at-root .header-curr__01 & {
      &:nth-of-type(1) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__01 .inview & {
      &:nth-of-type(1) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__02 & {
      &:nth-of-type(2) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__02 .inview & {
      &:nth-of-type(2) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__03 & {
      &:nth-of-type(3) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__03 .inview & {
      &:nth-of-type(3) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__04 & {
      &:nth-of-type(4) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__04 .inview & {
      &:nth-of-type(4) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__04--sec & {
      &:nth-of-type(4) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__04--sec .inview & {
      &:nth-of-type(4) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__05 & {
      &:nth-of-type(5) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__05 .inview & {
      &:nth-of-type(5) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__05 & {
      &:nth-of-type(5) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__05 .inview & {
      &:nth-of-type(5) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__05--sec & {
      &:nth-of-type(5) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__05--sec .inview & {
      &:nth-of-type(5) {
        @include header-arrow-inview();
      }
    }
    @at-root .header-curr__06 & {
      &:nth-of-type(6) {
        @include header-arrow();
        a {
          &::before {
            width: 100%;
            @include z-header(1);
          }
        }
      }
    }
    @at-root .header-curr__06 .inview & {
      &:nth-of-type(6) {
        @include header-arrow-inview();
      }
    }
  }
  &__sub {
    background-color: #fff;
    border-top: $color-bgGray 1px solid;
    display: none;
    padding: 5px 20px;
    position: absolute;
    top: 80px;
    right: 0;
    text-align: right;
    width: 100%;
    @include z-header(0);
    li {
      display: inline-block;
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      + li {
        margin-left: 40px;
      }
      a {
        @media (--large) {
          transition: opacity .3s;
          &:hover {
            opacity: 0.5;
          }
        }
        small {
          font-size: 1.2rem;
          margin-left: 5px;
        }
      }
    }
  }
}
.openheader {
  display: none;
  background-color: #000;
  overflow-y: auto;
  height: 100%;
  padding: 0 env(safe-area-inset-right) 50px env(safe-area-inset-left);
  padding-bottom: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include z-header(0);
  &__head {
    font-size: 0;
    .head__logo {
      margin: 10px 0 10px 20px;
      vertical-align: middle;
      @media (--mobile) {
        margin: 15px 0 15px 10px;
      }
      .plane {
        fill: #fff;
      }
    }
    .head__language {
      @media (--mobile) {
        display: inline-block;
        font-size: 1rem;
        margin-left: 30px;
        vertical-align: middle;
      }
      a,
      span {
        color: $color-baseText;
        &.is-curr {
          color: #fff;
        }
      }
    }
  }
  &__inner {
    position: relative;
  }
  &__menu {
    margin-top: 45px;
    li {
      font-size: 1.2rem;
      letter-spacing: 0.2em;
      position: relative;
      padding-left: 25px;
      &::before {
        background-color: #fff;
        content: "";
        height: 1px;
        position: absolute;
        top: 6px;
        left: 0;
        width: 18px;
      }
      + li {
        margin-top: 35px;
      }
      a {
        color: #fff;
      }
      p {
        font-size: 1rem;
        margin-top: 20px;
        position: relative;
        padding-left: 20px;
        &::before {
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          content: "";
          height: 4px;
          margin: auto;
          position: absolute;
          top: 0;
          left: 3px;
          bottom: 0;
          width: 4px;
          transform: rotate(45deg);
        }
      }
      .js-globalOpen {
        display: none;
      }
    }
  }
  &__sns {
    position: absolute;
    right: 25px;
    bottom: 0;
    li {
      display: inline-block;
      a {
        display: block;
      }
    }
    &--twitter {
      svg {
        height: 14px;
        width: 17px;
      }
      .plane {
        fill: #fff;
      }
    }
    &--facebook {
      margin-left: 10px;
      svg {
        height: 14px;
        width: 7px;
      }
      .plane {
        fill: #fff;
      }
    }
  }
}
