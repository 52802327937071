.linkarea {
  background-color: $color-bgGray;
  @media (--large) {
    margin-top: 80px;
    padding: 120px 0;
  }
  &__list {
    background-color: #fff;
    box-shadow: 0 2px 43px rgba(4, 0, 0, 0.1);
    margin: 0 auto;
    @media (--large) {
      display: flex;
      width: 960px;
    }
    &:nth-of-type(1) {
      @media (--mobile) {
        padding-bottom: 60px;
      }
    }
    + .linkarea__list {
      @media (--large) {
        margin-top: 80px;
      }
      @media (--mobile) {
        padding-bottom: 40px;
      }
    }
  }
  &__photo {
    overflow: hidden;
    position: relative;
    @media (--large) {
      width: 340px;
    }
    img {
      @media (--large) {
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @media (--mobile) {
        width: 100%;
      }
    }
  }
  &__text {
    padding: 35px 30px 20px;
    width: 620px;
    @media (--mobile) {
      margin: 0 auto;
      padding: 18px 0 0;
      width: 84%;
    }
    dl {
      dt {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        @media (--mobile) {
          font-size: 1.7rem;
          letter-spacing: 0.2em;
        }
      }
      dd {
        line-height: 2;
        margin-top: 20px;
        letter-spacing: 0.1em;
        @media (--mobile) {
          letter-spacing: 0.07em;
          margin-top: 7px;
        }
      }
    }
    .c-btn {
      margin: 10px 0 0 auto;
      width: 149px;
      @media (--mobile) {
        width: 100%;
      }
      a {
      }
    }
  }
}
