.p-document {
  &__mv {
    height: 680px;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.1em;
  }
}
.document {
  margin: 80px auto 0;
  width: 960px;
  @media (--mobile) {
    margin: 0 auto;
    padding: 40px 0 0;
    width: 100%;
  }
  &__inner {
    @media (--mobile) {
      margin: 0 auto;
      width: 90%;
    }
  }
  &__text {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    line-height: 1.8;
    margin: 40px auto 30px;
    @media (--mobile) {
      font-size: 1.4rem;
      margin: 30px auto 20px;
    }

  }
  &__list {
    margin-top: 36px;
    @media (--large) {
      display: flex;
    }
    @media (--mobile) {
      margin-top: 30px;
    }
  }
  &__listcont {
    background-color: #fff;
    padding: 5px;
    vertical-align: top;
    width: 318px;
    @media (--large) {
      height: auto;
    }
    @media (--mobile) {
      padding: 0 0 30px;
      width: 100%;
    }
    + li {
      @media (--large) {
        margin-left: 3px;
      }
    }
    figure {
    }
    figcaption {
    }
    &.no-images {
      .document__listphoto {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include z-contents(0);
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  &__listphoto {
    height: 205px;
    overflow: hidden;
    position: relative;
    @media (--mobile) {
      height: 282px;
    }
    img {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      max-width: none;
      width: auto;
    }
  }
  &__listttl {
    background-color: #7fa489;
    color: #fff;
    letter-spacing: 0.1em;
    padding: 14px 10px;
    width: 100%;
    @media (--mobile) {
      padding: 17px 10px;
    }
  }
  &__listtext {
    line-height: 1.5;
    letter-spacing: 0.1em;
    padding: 20px 5px;
    height:95px;
    @media (--mobile) {
      margin: 0 auto;
      padding: 20px 0;
      width: 90%;
      height:auto;
    }
  }
  .c-btn {
    @media (--large) {
      max-width: 100%;
    }
    @media (--mobile) {
      margin: 10px auto 0;
      width: 90%;
    }
  }
}
