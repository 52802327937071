
@keyframes stroke {
  0% {
    stroke-dasharray: 0,315,0,0;
  }
  50% {
    stroke-dasharray: 315,315,0,0;
  }
  100% {
    stroke-dasharray: 0,315,0,0;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes arrow-hover-left {
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes arrow-hover-right {
  50% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}
