.l-pageMove {
  background-color: #000;
  height: 10px;
  position: fixed;
  top: 80px;
  left: 0;
  width: 0;
  @include z-header(1);
  @media (--mobile) {
    top: 55px;
    height: 5px;
  }
  @at-root .p-top & {
    background-color: #fff;
    top: 0;
    @include z-loading(1);
    @media (--mobile) {
      background-color: #000;
      top: 55px;
      height: 5px;
    }
  }
}
.l-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  @include z-loading(1);
  &__bg {
    background-color: #000;
    height: 100%;
    width: 100%;
    @include z-loading(0);
  }
  &__loader {
    background-color: #fff;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    @include z-loading(1);
  }
}
