
.second {
  &__moviebtn {
    @extend .mv__moviebtn;
    background-color: transparent;
    margin-top: 80px;
    position: static;
    @media (--mobile) {
      margin-top: 40px;
    }
  }
}
