
html {
  font-size: 62.5%; //1.0rem = 10px
}
body {
  color: $color-baseText;
  font-family: $fontfamily;
  font-size: 1.0rem;
  letter-spacing: 0;
  //font-feature-settings: "palt";
  width: 100%;
}
.gotham {
  font-family: $fontGotham;
}
.hiragino {
  font-family: $fontfamily;
}
a {
  color: $color-baseText;
  text-decoration: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

//text
h1, h2, h3, h4, h5 ,h6, div, dl, dt, dd, p {
  margin:0;
  padding: 0;
}

ul,ol,li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

img {
  vertical-align: bottom;
}
figure{
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"]{
  -moz-appearance:textfield;
}

@media (--mobile) {
  html,body{
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, h5 ,h6, div, dl, dt,dd, p {
    margin:0;
    padding: 0;
  }

  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}
