.p-coming {
  background-color: $color-bgGray;
}
.p-404,
.p-coming {
  &__mv {
    height: 680px;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: 0.2em;
    @media (--mobile) {
      font-size: 1.6rem;
      text-align: center;
    }
  }
}
.notfound {
  background: url(../images/404/pix.png) no-repeat right top;
  background-size: auto 210px;
  margin: 80px auto 0;
  width: 960px;
  @media (--large) {
    min-height: 210px;
  }
  @media (--mobile) {
    background: url(../images/404/pix.png) no-repeat center 150px;
    background-size: auto 210px;
    margin: 0 auto;
    padding: 40px 0 0;
    width: 90%;
  }
  &__text {
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 0.15em;
    margin-top: 30px;
    @media (--mobile) {
      letter-spacing: 0.05em;
      margin: 20px auto 0;
      padding-bottom: 250px;
      width: 290px;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
  }
  &__btnarea {
    margin-top: 160px;
    text-align: center;
    @media (--mobile) {
      margin-top: 0;
    }
    a {
      background-color: $color-baseBlue;
      border: 0;
      cursor: pointer;
      color: #fff;
      display: inline-block;
      margin: 0 auto;
      outline: 0;
      padding: 10px;
      position: relative;
      transition: color .3s;
      width: 400px;
      &::before {
        background-color: $color-lightText;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        transition: width .8s;
      }
      @media (--large) {
        &:hover {
          color: $color-baseBlue;
          &::before {
            width: 100%;
          }
        }
      }
      span {
        position: relative;
      }
      @media (--mobile) {
        font-size: 1.2rem;
        width: 100%;
      }
    }
  }
}
