.p-privacy {
  &__ttl {
    font-weight: normal;
    font-size: 2.1rem;
    letter-spacing: 0.4em;
    margin-bottom: 30px;
    @media (--mobile) {
      font-size: 1.7rem;
      letter-spacing: 0.25em;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
.privacy {
  letter-spacing: 0.1em;
  line-height: 2;
  margin: 80px auto 0;
  position: relative;
  width: 960px;
  @media (--mobile) {
    width: 82%;
  }
  &__info {
    margin-bottom: 65px;
    @media (--mobile) {
      margin-bottom: 44px;
    }
  }
  h2 {
    line-height: 1;
  }
  dl {
    @media (--large) {
      margin-left: 122px;
      width: 720px;
    }
    + dl {
      margin-top: 69px;
      @media (--mobile) {
        margin-top: 45px;
      }
    }
    dt {
      margin-bottom: 27px;
      @media (--mobile) {
        margin-bottom: 7px;
      }
    }
    dd {
      ul {
        li {
          padding-left: 1em;
          text-indent: -1em;
          @at-root .p-privacy--en & {
            padding-left: 2em;
            text-indent: -2em;
          }
          div {
            text-indent: 0;
          }
        }
      }
      .c-btn__line {
        word-wrap: break-word;
      }
    }
  }
  &__marginlist {
    li {
      + li {
        margin-top: 20px;
      }
    }
  }
}
