
.contentsbox {
  background-color: #fff;
  box-shadow: 0 2px 21px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 30px 40px;
  position: relative;
  width: 960px;
  @media (--mobile) {
    padding: 35px 20px;
    width: 92%;
  }
  @at-root .p-about & {
    padding: 70px 40px 70px;
    @media (--mobile) {
      padding: 40px 20px 30px;
    }
    &:nth-of-type(1) {
      padding: 30px 40px 70px;
      @media (--mobile) {
        padding: 40px 20px 30px;
      }
    }
  }
  + .contentsbox {
    margin-top: 110px;
    @media (--mobile) {
      margin-top: 40px;
    }
  }
  &__numb {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    color: $color-lightText;
    display: flex;
    font-size: 2rem;
    height: 59px;
    padding-left: 13px;
    position: absolute;
    top: 285px;
    left: -30px;
    width: 59px;
    @include z-contents(1);
    @media (--mobile) {
      font-size: 1.5rem;
      height: 30px;
      justify-content: center;
      padding-left: 0;
      top: 130px;
      left: -15px;
      width: 30px;
    }
  }
  &__ttl {
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 2;
    text-align: center;
    @media (--mobile) {
      font-size: 1.7rem;
    }
    @at-root .p-about & {
      line-height: 1.6;
    }
    @at-root .p-interview & {
      + .contentsbox__text {
        margin-top: 60px;
        @media (--mobile) {
          margin-top: 25px;
          font-size: 1.5rem;
          letter-spacing: 0.3em;
        }
      }
    }
    + .contentsbox__text {
      margin-top: 20px;
    }
  }
  &__catch {
    font-size: 2.4rem;
    letter-spacing: 0.1em;
    margin-top: 20px;
    text-align: center;
    @media (--mobile) {
      font-size: 1.7rem;
      letter-spacing: 0.2em;
      margin-top: 0;
    }
  }
  figure {
    margin-top: 30px;
    @media (--mobile) {
      margin-top: 10px;
    }
    @at-root .p-interview & {
      + .contentsbox__ttl {
        margin-top: 60px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
    }
    + .contentsbox__ttl {
      margin-top: 60px;
      @at-root .p-about & {
        @media (--mobile) {
          margin-top: 45px;
        }
      }
    }
    figcaption {
      margin-top: 35px;
      @media (--mobile) {
        margin-top: 30px;
      }
      @at-root .p-about & {
        @media (--mobile) {
          margin-top: 10px;
        }
      }
    }
  }
  &__photo {
    text-align: center;
  }
  &__text {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.11em;
    @media (--mobile) {
      font-size: 1.4rem;
    }
    @at-root .p-interview & {
      + .contentsbox__text {
        margin-top: 60px;
        @media (--mobile) {
          margin-top: 25px;
        }
      }
      &--guest {
        color: #5e441c;
      }
    }
    &--last {
      @extend .contentsbox__text;
      font-weight: bold;
    }
  }
  &__dl {
    margin-top: 40px;
    @media (--large) {
      display: flex;
    }
    @media (--mobile) {
      margin-top: 15px;
    }
    + .contentsbox__dl {
      margin-top: 60px;
      @media (--mobile) {
        margin-top: 30px;
      }
    }
    dt {
      font-size: 2rem;
      text-align: center;
      @media (--large) {
        width: 266px;
      }
      @media (--mobile) {
        font-size: 1.7rem;
      }
      span {
        border: $color-lightText 1px solid;
        display: block;
        padding: 16px 0;
        @media (--mobile) {
          padding: 8px 0;
        }
      }
    }
    dd {
      font-size: 1.6rem;
      line-height: 2;
      word-wrap:break-word;
      @media (--large) {
        margin-left: 40px;
        width: 562px;
      }
      @media (--mobile) {
        font-size: 1.4rem;
        margin-top: 10px;
      }
      small {
        display: block;
        font-size: 1.4rem;
        @media (--mobile) {
          font-size: 1.2rem;
          line-height: 1.9;
        }
      }
    }
  }
}
