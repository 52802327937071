
.l-footer {
  background-color: $color-bgGray;
  @at-root .p-lovot & {
    background-color: $color-bglovot;
  }
  @media (--large) {
    border-top: $color-lightText 1px solid;
    padding: 80px 0 160px;
  }
  @media (--mobile) {
    background-color: #fff;
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
    @at-root .p-lovot & {
      background-color: #fff;
    }
  }
  &__menu {
    background-color: $color-bgGray;
    li {
      background-color: $color-lightGray;
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      + li {
        margin-top: 1px;
      }
      a {
        display: block;
        font-weight: bold;
        padding: 11px 25px;
      }
      .js-globalOpen {
        display: none;
      }
      p {
        margin-top: 1px;
        a {
          background-color: #fff;
          font-size: 1rem;
          padding-left: 35px;
        }
      }
    }
  }
  &__inner {
    @extend .clearfix;
    margin: 0 auto;
    position: relative;
    width: 965px;
    @media (--mobile) {
      margin: 0 auto;
      padding: 40px 0;
      width: 86%;
    }
  }
  &__privacy {
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    @media (--large) {
      text-align: right;
      position: absolute;
      top: 230px;
      right: 0;
    }
    @media (--mobile) {
      font-size: 1rem;
      margin-top: 20px;
    }
    &--en {
      @extend .l-footer__privacy;
      @media (--large) {
        top: 80px;
      }
    }
  }
  &__copy {
    font-size: 1.2rem;
    letter-spacing: 0.14em;
    margin-top: 55px;
    @media (--mobile) {
      font-size: 1rem;
      margin-top: 20px;
    }
    &--en {
      @extend .l-footer__copy;
      margin-top: 70px;
    }
  }
  &__logo {
    background-color: $color-bgGray;
    padding: 20px 0;
    text-align: center;
    img {
      height: 33.5px;
      width: 118.5px;
    }
  }
}
.snsmenu {
  float: right;
  font-size: 0;
  position: relative;
  @include z-footer(1);
  @media (--mobile) {
    margin-right: 5px;
  }
  &__btn {
    display: inline-block;
    &:first-child {
      height: 13px;
      width: 15px;
      @media (--mobile) {
        height: 10.5px;
        width: 12.5px;
      }
    }
    &:last-child {
      margin-left: 20px;
      height: 17px;
      width: 9px;
      @media (--mobile) {
        margin-left: 10px;
        height: 10.5px;
        width: 5.5px;
      }
    }
    a {
      transition: opacity .3s;
      &:hover {
        @media (--large) {
          opacity: .5;
        }
      }
      .plane {
        fill: $color-baseText;
      }
    }
  }
}
.foot {
  font-style: normal;
  font-size: 1.4rem;
  position: relative;
  @media (--mobile) {
    font-size: 1rem;
  }
  &:nth-of-type(2) {
    margin-top: 65px;
    position: static;
  }
  &--en {
    font-size: 1.2rem;
  }
  &__name {
    letter-spacing: 0.15em;
  }
  &__language {
    color: $color-lightText;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 380px;
    @media (--mobile) {
      left: 140px;
    }
    a,
    span {
      color: $color-lightText;
      + a,
      + span {
        margin-left: 20px;
        @media (--mobile) {
          margin-left: 10px;
        }
      }
      &.is-curr {
        color: $color-baseText;
      }
    }
    a[href] {
      @media (--large) {
        transition: opacity .3s;
        &:hover {
          opacity: .5;
        }
      }
    }
  }
  &__address {
    margin-top: 30px;
    letter-spacing: 0.15em;
    line-height: 1.7;
    @media (--mobile) {
      line-height: 1.5;
      margin-top: 15px;
    }
  }
}
