.p-news {
  &__mv {
    height: 680px;
    @media (--mobile) {
      height: 375px;
    }
  }
  &__ttl {
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    @media (--mobile) {
      margin-bottom: 25px;
      padding-left: 20px;
    }
  }
}
.news {
  margin: 45px auto 0;
  position: relative;
  width: 960px;
  @media (--mobile) {
    margin: 0;
    padding: 25px 0 0;
    width: 100%;
  }
  @at-root .p-top & {
    margin: 105px auto 0;
    @media (--mobile) {
      margin: 0 auto 0;
      padding: 25px 0 0;
    }
  }
  &__feature {
    background-color: $color-baseBlue;
    margin-bottom:40px;
    padding: 20px;
    @media (--mobile) {
      margin-bottom: 20px;
      padding: 15px;
      @at-root .p-top & {
        padding: 10px 15px 15px;
      }
    }
  }
  &__newarrivals {
    background-color: $color-baseBlue;
    padding: 20px;
    @media (--mobile) {
      padding: 15px;
    }
    @at-root .p-top & {
      @media (--mobile) {
        padding: 15px 15px 72px;
      }
    }
  }
  &__arrivalsttl {
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    @media (--mobile) {
      font-size: 1.2rem;
      text-align: center;
    }
  }
  &__category {
    background-color: #323d4e;
    font-size: 0;
    li {
      border-bottom: $color-baseBlue 1px solid;
      background-color: #323d4e;
      cursor: pointer;
      color: #6e7786;
      display: inline-block;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      padding: 15px 0;
      text-align: center;
      width: calc(100% / 6);
      @media (--mobile) {
        font-size: 1rem;
        padding: 10px 0;
        width: 33.3%;
      }
      &.is-select {
        background-color: $color-baseBlue;
        color: #fff;
      }
      + li {
        border-left: $color-baseBlue 1px solid;
      }
    }
  }
  &__scroll {
    @at-root .p-top & {
      @media (--large) {
        background-color: #fff;
        overflow-x: auto;
        height: 780px;
      }
    }
  }
  &__list {
    padding: 35px 25px 35px 40px;
    flex-flow: wrap;
    @media (--large) {
      background-color: #fff;
      display: flex;
    }
    @media (--mobile) {
      padding: 0;
    }
  }
  &__article {
    @media (--large) {
      min-height: 180px;
      width: 408px;
    }
    @media (--mobile) {
      background-color: #fff;
      position: relative;
      padding: 30px 30px;
    }
    // &.is-open {
    //   .news__text {
    //     .photo {
    //       display: block;
    //     }
    //     dl {
    //       dd {
    //         display: block;
    //       }
    //     }
    //   }
    //   .news__btn {
    //     background-color: $color-bgGray;
    //     &::before {
    //       background-color: $color-baseBlue;
    //       transform: rotate(45deg);
    //     }
    //     &::after {
    //       background-color: $color-baseBlue;
    //       transform: rotate(45deg);
    //     }
    //   }
    // }
    + li {
      @media (--mobile) {
        padding: 10px 30px 30px;
      }
    }
    &:nth-of-type(even){
      @media (--large) {
        margin-left: 29px;
      }
    }
    &:nth-of-type(n + 3){
      @media (--large) {
        margin-top: 50px;
      }
    }
  //   @at-root .p-top .news__list--new & {
  //     @media (--large) {
  //       min-height: 0;
  //     }
  //     @media (--mobile) {
  //       &:nth-of-type(n + 9){
  //         display: none;
  //       }
  //     }
  //     + li {
  //       @media (--large) {
  //         margin-top: 35px;
  //       }
  //     }
  //   }
    a {
      @media (--large) {
        &:hover {
          .news__text {
            .photo {
              img {
                transform: translate(-50%,-50%) scale(1.1);
              }
            }
          }
        }
      }
    }
    .c-btn {
      line-height: 1.2;
      margin-top: 12px;
      @media (--mobile) {
        margin: 0 auto;
        width: 100%;
      }
      @at-root .p-top .news__list--new & {
        @media (--large) {
          height: 100%;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          a {
            border: 0;
            height: 100%;
            text-indent: -9999px;
            transition: background-color .3s;
            &::before {
              display: none;
            }
            &:hover {
              background-color: rgba(255,255,255,0.5);
            }
          }
        }
      }
    }
  }
  &__btn {
    background-color: #323d4e;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: background-color .3s;
    width: 20px;
    &::before {
      background-color: #fff;
      content: "";
      height: 1px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 7px;
    }
    &::after {
      background-color: #fff;
      content: "";
      height: 7px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 1px;
    }
  }
  &__text {
    @media (--mobile) {
      margin-bottom: 15px;
    }
    .photo {
      overflow: hidden;
      @media (--large) {
        height: 205px;
        width: 408px;
        position: relative;
        img {
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          transition: transform .3s;
          width: auto;
        }
      }
      @media (--mobile) {
        line-height: 0;
        margin-bottom: 15px;
        img {
          height: auto;
          max-height: none;
          width: 100%;
        }
      }
      // @at-root .p-top .news__list--new & {
      //   @media (--large) {
      //     display: none;
      //   }
      // }
    }
    dl {
      line-height: 2;
      position: relative;
      @media (--large) {
        margin-top: 20px;
        width: 408px;
      }
      // @at-root .p-top .news__list--new & {
      //   line-height: 1.9;
      //   width: 100%;
      // }
      dt {
        font-weight: bold;
        letter-spacing: 0.12em;
        position: relative;
        @media (--mobile) {
          line-height: 1.5;
        }
        span {
          display: block;
          font-size: 1.2rem;
          font-weight: normal;
          margin-bottom: 5px;
          line-height: 1;
          @media (--mobile) {
            font-size: 1rem;
          }
          // @at-root .p-top .news__list--new & {
          //   @media (--large) {
          //     display: inline-block;
          //     margin-bottom: 0;
          //   }
          //   @media (--mobile) {
          //     margin-bottom: 12px;
          //   }
          // }
        }
        small {
          font-size: 1.2rem;
          font-weight: normal;
          line-height: 1;
          position: absolute;
          top: 0;
          left: 130px;
          @media (--mobile) {
            font-size: 1rem;
            top: 0;
            left: 120px;
          }
        }
        // @at-root .p-top .news__list--new & {
        //   @media (--large) {
        //     width: 100%;
        //   }
        // }
      }
      dd {
        hyphens: auto;
        letter-spacing: 0.1em;
        margin-top: 5px;
        @media (--mobile) {
          margin-top: 10px;
        }
        // @at-root .p-top .news__list--new & {
        //   @media (--large) {
        //     margin-top: 0;
        //     width: 100%;
        //   }
        // }
      }
    }
  }
  &__icon {
    background: url(../images/news/icon_feature.svg) no-repeat right top;
    background-size: 18px 18px;
    color: $color-lovotRed;
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0em;
    line-height: 18px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 20px;
    @media (--mobile) {
      background-size: 15px 15px;
      line-height: 15px;
      padding-right: 18px;
    }
  }
  .mCSB_inside > .mCSB_container {
    @media (--large) {
      margin-right: 10px;
    }
  }
  .mCSB_scrollTools {
    opacity: 1;
    width: 8px;
    .mCSB_draggerRail {
      background-color: #cfcfcf;
      border-radius: 0;
      width: 8px;
    }
    .mCSB_dragger {
      height: 100px;
      .mCSB_dragger_bar {
        background-color: #323d4e;
        border-radius: 0;
        width: 8px;
      }
    }
  }
}

.noLink {
  display: block;
  pointer-events: none;
}